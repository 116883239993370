import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    searchTerm: {
        width: "100%"
    },
    accessHolderCategory: {
        width: "100%"
    },
    chipGrid: {
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        },
        [theme.breakpoints.down("sm")]: {
            display: "inline-grid",
            justifyContent: "normal"
        },
    },
    submitBtn: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginBottom: theme.spacing(1)
        }
    }
}));