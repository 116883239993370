import React from "react";
import {Controller} from "react-hook-form";
import {FormControl, TextField, Tooltip} from "@material-ui/core";

export default function OrderFormField({control, errors}) {
  return (
    <FormControl>
      <Controller
        name="order"
        control={control}
        render={({field}) => (
          <Tooltip title="The order in which the script will be run." arrow>
            <TextField
              {...field}
              fullWidth
              label="Order"
              error={!!errors.order}
              helperText={errors.order && errors.order?.message}
              type="number"
              variant="outlined"
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
            />
          </Tooltip>
        )}
      />
    </FormControl>
  );
}
