import React from "react";
import {
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Box,
  Paper,
  Typography
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {useDeployableScriptsContext} from "../DeployableScriptsProvider";
import ManifestMenu from "../ManifestMenu";
import {useStyles} from "../styles";

function filterRows(typeFilter, rows) {
  if (!rows) {
    return [];
  }
  if (typeFilter === -1) {
    return rows.filter(r => r.deviceTypeId === null).map(r => ({...r, editable: true}));
  }
  if (typeFilter > 0) {
    return rows
      .filter(r => r.deviceTypeId === typeFilter || r.deviceTypeId === null)
      .map(r => ({...r, editable: r.deviceTypeId === typeFilter}));
  }
  return [];
}

const ScriptsTable = ({typeFilter}) => {
  const classes = useStyles();
  const {
    state: {scripts: tableRows},
    actions: {addClick, editClick, deleteClick, getTypeNameFromTypeId}
  } = useDeployableScriptsContext();
  return <>
    <Box display="flex" my={2}>
      <Box flexGrow={1}>
        <Typography variant="h5" gutterBottom>
          Scripts for {getTypeNameFromTypeId(typeFilter)} Devices
        </Typography>
      </Box>
      <Box mx={2}>
        <Button variant="outlined" onClick={() => addClick(typeFilter > 0 ? typeFilter : null)}>Add</Button>
      </Box>
      <Box>
        <ManifestMenu typeId={typeFilter}> </ManifestMenu>
      </Box>
    </Box>
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Retries</TableCell>
            <TableCell align="center">Timeout</TableCell>
            <TableCell align="center">Continue-On-Error</TableCell>
            <TableCell align="center">Environment</TableCell>
            <TableCell align="center">Order</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterRows(typeFilter, tableRows).map((row) => {
            const rowClassName = row.editable ? "" : classes.disabledRow;
            return (
              <TableRow key={row.id}>
                <TableCell className={rowClassName} component="th" scope="row">
                  {row.fileName}
                </TableCell>
                <TableCell className={rowClassName} align="center">{row.runType}</TableCell>
                <TableCell className={rowClassName} align="center">{row.retryCount}</TableCell>
                <TableCell className={rowClassName} align="center">{row.timeoutSeconds}</TableCell>
                <TableCell className={rowClassName} align="center">{row.continueOnError.toString()}</TableCell>
                <TableCell className={rowClassName} align="center">{row.environment}</TableCell>
                <TableCell className={rowClassName} align="center">{row.order}</TableCell>
                <TableCell className={rowClassName} align="right">
                  {row.editable && (<>
                    <IconButton aria-label="edit" onClick={() => editClick(row.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => deleteClick(row.id)}>
                      <DeleteIcon/>
                    </IconButton>
                  </>)}
                </TableCell>
              </TableRow>);
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </>
};

export default ScriptsTable;
