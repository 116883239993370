import React from "react";
import apiClient from "../../../../auth/apiClient";
import RefreshIcon from '@material-ui/icons/Refresh';
import {IconButton} from "@material-ui/core";
import useCancellationToken from "../../../../hooks/useCancellationToken";
import {useDeployableScriptsContext} from "../DeployableScriptsProvider";

const RefreshAvailableScriptsButton = () => {
  const {actions: {setAvailableScripts, showError}} = useDeployableScriptsContext();
  const {execute: getScripts, inProgress} = useCancellationToken({
    func: async function ({cancelToken}) {
      const response = await apiClient.get(`deployable-scripts/configuration/available-scripts`, {
        cancelToken: cancelToken
      });
      setAvailableScripts(response.data);
    }, errHandleFunc: (err) => {
      showError('Failed to load available scripts' + err);
    },
  });

  return <IconButton disabled={inProgress} aria-label="refresh scripts" onClick={getScripts}>
    <RefreshIcon/>
  </IconButton>
};

export default RefreshAvailableScriptsButton;
