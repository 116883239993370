import React, { useCallback, useEffect, useState } from "react";

import clsx from "clsx";
import { useStyles } from "./AccessGroup.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { Typography } from "@material-ui/core";
import SquareChip from "../../SquareChip"
import { AUDIT_TYPES, AUDIT_SUBTYPES } from "../../../constants";

import AccessGroupService from "../../../services/AccessGroupService";
import apiClient from "../../../auth/apiClient";

const AccessGroupAudit = ({ onDrawer, username, action, date, payload, ...props }) => {
  const accessGroupService = new AccessGroupService(apiClient);

  const classes = useStyles();
  const _payload = JSON.parse(props?.history?.payload) ?? null;

  const [deletedName, setDeletedName] = useState(null);
  
  const getActionLabel = () => {
    switch (action) {
      case "add":
        return "Created";
      case "edit":
        return "Edited";
      case "delete":
        return "Deleted";
      default:
        return action;
    }
  };

  const getAccessGroupName =  () => {
    switch (action) {
      case "add":
        return  _payload?.accessGroupInfo?.Name;
      case "edit":
        return   _payload?.accessGroup?.Name;
      case "delete":
        //return deletedName;   //whenever AccessGroup GET allows you to get deleteds
        return _payload?.accessGroupID;

      default:
        return   null;
    }
  };



  const handleDetailsClick = () => {
    onDrawer("access group " + date.toString());
  }

  //comment this out if accessGroupService.GetAccessGroup ever lets you bring back a deleted oned
  // const fetchGroupName = useCallback(
  //   async (_facilityID, _groupID) =>{
  //     let accessObject;
  //     try {
  //       accessObject = await accessGroupService.GetAccessGroup(_facilityID, _groupID);
  //       setDeletedName(accessObject.Name);
  //     } catch (e) {
  //       //
  //     }
  //   }
  // );
  // useEffect(async () => {
  //   //if its a delete, go get its name
  //   if (action=="delete"){
  //     //fetchGroupName(_payload?.entityID, _payload.accessGroupID);
  //   }
  //   return () => {};
  // }, []);
  

  return (
    <>
      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}><SquareChip size="small" color="primary" text={AUDIT_TYPES[props?.history?.category]}/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action" className={clsx([classes.tdc])}>
          <div>{getActionLabel()}</div>
          <div className={clsx([action, classes.groupName])}>{getAccessGroupName()}</div>
        </td>
        <td data-column="Details" className={clsx([classes.tdc,classes.details])}>
          {1==2 && (
          <FontAwesomeIcon onClick={handleDetailsClick} size="lg" className={classes.detailIcon} alt={JSON.stringify(_payload)} title={JSON.stringify(_payload)} icon={faInfoCircle}></FontAwesomeIcon>
          )}
        </td>
      </tr>
    </>
  );
};

export default AccessGroupAudit;
