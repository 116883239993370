import React, { useState, useEffect } from "react";
import { useStyles, StyledTableCell } from "./styles";
import { Container, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useHubContext from "../../hooks/useHubContext";
import { MONEY_INVENTORY_TOPIC, MONEY_INVENTORY_REQUEST_TOPIC, PORTAL_TRIGGER } from "../../constants";

export const BNRTotals = ({ entityid }) => {
  const classes = useStyles();
  const { portalHub } = useHubContext();
  const [billGroupings, setBillGroupings] = useState(billGroupingStarter);

  const createSubRow = (label, value) => (
    <TableRow>
      <TableCell colSpan={9} className={classes.subheader}>{label}</TableCell>
      <TableCell align="right" colSpan={3}>{`$${value?.toFixed(2)}`}</TableCell>
    </TableRow>
  );

  useEffect(() => {
    // Subscribe to money inventory topic prefixed with this entityId
    portalHub.subscribe(
      `${entityid}_${MONEY_INVENTORY_TOPIC}`,
      (message) => {
        var data = JSON.parse(message);
        if (data.Action === "CashInventoryResponse") {
          var messageData = JSON.parse(data.Message);
          setBillGroupings(messageData.bills); 
        }
      }
    );

    // On render invoke money inventory request
    portalHub.invoke(PORTAL_TRIGGER, {
      entityID: entityid,
      topic: MONEY_INVENTORY_REQUEST_TOPIC,
      method: "",
      action: "CashInventoryRequest",
      message: "",
    });
    
    return () => {
      portalHub.unsubscribe(`${entityid}_${MONEY_INVENTORY_TOPIC}`);
    };
  }, [portalHub.connection, portalHub.isOpen]);

  // Grab all of the bill groupings that are in the cashbox drawer
  const cashBox = billGroupings.filter(x => x.DrawerName.toLowerCase() === "cb");

  // The count of bills in the cash box is just the sum of all the counts
  const cashBoxCount = cashBox.reduce((a, b) => a + (b.Count || 0), 0);

  // Total up all of the cashbox billgroupings values
  const cashBoxTotal = cashBox.reduce((a, b) => a + ((b.Amount * b.Count) || 0), 0);

  // Recycler total is the total of all the groupings that aren't cashbox
  const recyclerTotal = billGroupings.filter((grouping) => grouping.DrawerName.toLowerCase() !== "cb")
    .reduce((a, b) => a + ((b.Amount * b.Count) || 0), 0);

  // Don't have to filter total
  const total = billGroupings.reduce((a, b) => a + ((b.Amount * b.Count) || 0), 0);

  return (
    <Container className={classes.tablecontainer}>
      <Table data-id={`cash-status-${entityid}`} className={classes.table} aria-label="spanning table">

        <TableBody>
          <TableRow>
            <TableCell align="right" colSpan={6} className={classes.subheader}>Recycler Value</TableCell>
            <TableCell align="right" colSpan={3} className={classes.subheader}>Count</TableCell>
            <TableCell align="right" colSpan={3} className={classes.subheader}>Total</TableCell>
          </TableRow>

          {billGroupings?.map((denom) => (
            denom.DrawerName.toLowerCase() !== "cb" && 
              <TableRow key={`denom-${denom.DrawerName}`} style={{ borderTopWidth: '0px' }}>
                <StyledTableCell colSpan={3}></StyledTableCell>
                <StyledTableCell align="right" colSpan={3}>{denom.Amount}</StyledTableCell>
                <StyledTableCell align="right" colSpan={3}>{denom.Count}</StyledTableCell>
                <StyledTableCell align="right" colSpan={3}>{`$${(denom.Amount * denom.Count).toFixed(2)}`}</StyledTableCell>
              </TableRow>
          ))}

          {createSubRow("Recycler Total", recyclerTotal)}

          <TableRow>
            <TableCell colSpan={6} className={classes.subheader}>Cash Box</TableCell>
            <TableCell align="right" colSpan={3}>
              {cashBoxCount}
            </TableCell>
            <TableCell align="right" colSpan={3}>
              {`$${cashBoxTotal.toFixed(2)}`}
            </TableCell>
          </TableRow>

          {createSubRow("Total", total)}
        </TableBody>
      </Table>
    </Container>
  )
}

export default BNRTotals;

export const billGroupingStarter = [
  {
    DrawerName: "D1",
    Count: 0,
    Amount: 1,
  },
  {
    DrawerName: "D5",
    Count: 0,
    Amount: 5,
  },
  {
    DrawerName: "D10",
    Count: 0,
    Amount: 10,
  },
  {
    DrawerName: "D20",
    Count: 0,
    Amount: 20,
  },
  {
    DrawerName: "CB",
    Count: 0,
    Amount: 0,
  }
];