import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./style";
import { useHistory, useParams } from "react-router-dom";
import AccessHolderForm from "../../Forms/AccessHolder";
import { isUndefined } from "lodash";

const CreateEditAccessHolder = ({
  onDelete,
  accessHolderID,
  facilityID,
  onChange,
  unMount,
}) => {
  const history = useHistory();
  const { accessHolderID: routeID } = useParams();

  accessHolderID = !isUndefined(accessHolderID)
    ? accessHolderID
    : routeID !== "add"
    ? routeID
    : undefined;

  useEffect(() => {
    return () => unMount();
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div>
      <AccessHolderForm
        accessHolderID={accessHolderID}
        facilityID={facilityID}
        onCancel={handleCancel}
        onChange={onChange}
        onDelete={onDelete}
      />
    </div>
  );
};

CreateEditAccessHolder.defaultProps = {
  onDelete: () => {},
  unMount: () => {},
};

CreateEditAccessHolder.propTypes = {
  onDelete: PropTypes.func,
  unMount: PropTypes.func,
};

export default CreateEditAccessHolder;
