import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: { 
      width: '100%'
  }
}));

const AmiToast = React.forwardRef((props, ref) => {
  const variant = props.variant ? props.variant : "success";
  const classes = useStyles();
  return (
    <div className={clsx(props.fullwidth ? classes.root : "","amitoast")} data-tag={props.tag} data-variant={props.variant} data-message={props.message}>   
        <MuiAlert ref={ref} variant="filled" severity={variant} >
            {props.title && (<div>{props.title}</div>)}
            {props.message}
        </MuiAlert>      
    </div>
  );
});

AmiToast.displayName = "AmiToast";

export default AmiToast;