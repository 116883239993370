import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    width: "80%",
  },
  input: {
    width: "100%",
  },
  gridContainer: {
    alignContent: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1)
  },
  deleteBtnGrid: {
    textAlign: "right",
  },
  selectError: {
    color: "#f44336",
    marginLeft: "10px",
    fontSize: ".85em",
    marginTop: "5px",
  },
  btnContainer: {
    marginLeft: "0px",
  },
  searchBarContainer: {
    marginLeft: theme.spacing(1), 
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  paginationAlign: {
    display: "flex",
    marginTop: 10,
    alignContent: "right", 
    justifyContent: "right",
    [theme.breakpoints.down("sm")]: {
      alignContent: "center", 
      justifyContent: "center",
    }
  },
  buttonAlign: {
    marginTop: 12,
    marginLeft: 10
  },
  historyDialog: {
    minWidth: "50vw",
  },
}));
