import { purple, yellow } from "@material-ui/core/colors";
import { makeStyles, lighten, darken} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    width: "100%",
    display: "flex", 
    margin: theme.spacing(1),
    overFlowX: "none",
    minHeight: "calc(500%-16px)",
    maxHeight: "calc(500%-16px)",
  },
  addButton: {
    margin: theme.spacing(2)
  },
  searchBar: {
    marginLeft: theme.spacing(2)
  },
  filter: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  accessHolders: {
    marginLeft: theme.spacing(1),
    width: "100%"
  },
  paper: {
    width: "100%",
    minHeight: "calc(500%-16px)",
    maxHeight: "calc(500%-23px)"
  },
  root: {
  },
  filterIcon: {
    float: "right",
    marginTop: theme.spacing(1)
  },
  filterButtonContainer: {
    marginTop: theme.spacing(-1),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(4)
    }
  },
  drawerPaper: {
    width: "50%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      boxShadow: `0.5px 0.5px 0.5px 0.5px ${theme.palette.action.disabledBackground}`,
      backgroundColor:lighten(theme.palette.primary.light,.8),
    },
    transition: theme.transitions.create("box-shadow", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },
  pointer: {
    cursor: "pointer",
  },
  resultText: {
    background: theme.palette.secondary.main,
    color: "#fff"
  },
  accordionGeneral: {
    minHeight: "calc(500%-16px)",
    maxHeight: "calc(500%-23px)",
    overflowX: "none",
    overFlowY: "none",
  },
  resultStatus: {
  },
  paginationContainer: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2)
    }
  }
}));
