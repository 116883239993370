import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {},
  tableHeader: {
    textAlign:"left",
    color: theme.palette.primary.main,
  },


  longHeader: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.disabledBackground,
    '&.detail': {
      textAlign:"center",
    }
  },
  auditDrawer : {
    minWidth: "25vw",
  },


  audit: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    border:"1px solid green",
    minHeight:"50px"
  },
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  inputsGrid: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  inputGrid: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  buttonGrid: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
    },
    textAlign: "right",
    "@media print": {
      display: "none",
    },
  },
  fromDateGrid: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  errorGrid: {
    color: "red",
    alignSelf: "end",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
      paddingTop: theme.spacing(1),
    },
  },
  history: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
