import React from "react";
import {Controller} from "react-hook-form";
import {FormControl, InputLabel, MenuItem, Select, Box} from "@material-ui/core";
import RefreshAvailableScriptsButton from "../../RefreshAvailableScriptsButton";
import {useDeployableScriptsContext} from "../../DeployableScriptsProvider";

export default function ScriptFormField({control, errors}) {
  const {state: {availableScripts}} = useDeployableScriptsContext();
  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <FormControl fullWidth>
          <InputLabel>Script</InputLabel>
          <Controller
            name="fileName"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                defaultValue=""
                placeholder="Select a script to add…"
                fullWidth
                error={!!errors.fileName}>
                {availableScripts.map(s => {
                  return <MenuItem key={s} value={s}>{s}</MenuItem>
                })}
              </Select>
            )}
          />
        </FormControl>
      </Box>
      <RefreshAvailableScriptsButton></RefreshAvailableScriptsButton>
    </Box>
  );
}