import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  list: {
    width: "inherit",
  },
  chip: {
    cursor: "pointer",
    margin: "0 1%",
  },
  card: {
    width: "inherit",
    cursor: "pointer",
  },
  cardContent: {
    width: "100%",
  },
  listIcon: {
    minWidth: "50px",
  },
  chipGrid: {
    lineHeight: "50px",
  },
  icon: {
    cursor: "pointer",
  },
  filterBox: {
    marginBottom: theme.spacing(2),
  },
  filterChip: {
    cursor: "pointer",
    margin: "0 1%",
  },
  filterContainer: {
    width: "100%",
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  mobileAdd: {
    "& .MuiButton-startIcon": {
      margin: 0,
    },
    display: "flex",
    justifyContent: "flex-end"
  },
  addBtn: {
    display: "flex",
    justifyContent: "flex-end",
    maxHeight: "50px",
  },
  dateIndicator: {
    fontSize: "large",
    fontWeight: "bold",
  },
  editGrid: {
    textAlign: "right",
    margin: "0 auto",
  },
  loadingSpinner: {
    textAlign: "center",
  },
  divider: {
    backgroundColor: theme.palette.common.black
  },
  itemList: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important"
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2)
    }
  },
  contentRoot: {
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2)
    }
  }
}));
