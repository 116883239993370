import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MuiAlertTitle from "@material-ui/lab/AlertTitle";


const getTitle = type => {
  switch(type) {
    case "error":
      return "Error";
    case "warning":
      return "Warning";
    case "info":
      return "Info";
    default:
      return "Success";
  }
};

const Alert = ({open, onClose, type, showClose, showTitle, children}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >

      <MuiAlert 
        elevation={6}
        variant="filled" 
        severity={type ? type : "success"} 
        onClose={showClose ? onClose : undefined}
      >
        {showTitle && <MuiAlertTitle>{getTitle(type)}</MuiAlertTitle>}
        {children}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
