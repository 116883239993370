import React, {useEffect, useState} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
  LinearProgress
} from "@material-ui/core";
import {useDeployableScriptsContext} from "../DeployableScriptsProvider";
import useCancellationToken from "../../../../hooks/useCancellationToken";
import apiClient from "../../../../auth/apiClient";

const RebuildManifestsDialog = () => {
  const {
    state: {rebuildType},
    actions: {resetRebuild, showError, getTypeNameFromTypeId}
  } = useDeployableScriptsContext();
  const [open, setSetOpen] = useState(false);
  const [complete, setComplete] = useState(null);
  const [deviceType, setDeviceType] = useState(null);

  const {execute: refreshManifests, inProgress} = useCancellationToken({
    func: async function ({typeId, cancelToken}) {
      await apiClient.post(`deployable-scripts/manifest/rebuild/` + typeId, {
        cancelToken: cancelToken
      });
      resetRebuild();
      setComplete({success: true});
    }, errHandleFunc: (err) => {
      setComplete({success: false, error: err});
    },
  });

  const performRefresh = (type) => {
    refreshManifests({typeId: type});
  };

  const cancel = () => {
    setSetOpen(false);
    resetRebuild();
  };

  useEffect(() => {
    if (rebuildType) {
      setDeviceType(getTypeNameFromTypeId(rebuildType).toUpperCase());
      setSetOpen(true);
      setComplete(null);
      performRefresh(rebuildType);
    }
  }, [rebuildType]);

  return <Dialog open={open}>
    <DialogTitle>Rebuilding Manifests</DialogTitle>
    <DialogContent>
      {inProgress && (
        <>
          <DialogContentText>
            Rebuilding the {deviceType ?? ""} manifest. Please wait.
          </DialogContentText>
          <LinearProgress color="primary"/>
        </>
      )}
      {complete && (
        <>
          {complete.success ? (
            <DialogContentText>Rebuild is complete!</DialogContentText>
          ) : (
            <>
              <DialogContentText>
                <Typography color="error" variant="h5" gutterBottom>
                  Error
                </Typography>
                <Typography color="error" variant="body1" gutterBottom>
                  {complete.error.message}
                </Typography>
              </DialogContentText>
            </>
          )}
        </>
      )}
    </DialogContent>
    <DialogActions>
      {complete && (
        <Button onClick={cancel} disabled={inProgress} variant="outlined">
          Ok
        </Button>
      )}
    </DialogActions>
  </Dialog>
};

export default RebuildManifestsDialog;
