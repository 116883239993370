import { purple, yellow } from "@material-ui/core/colors";
import { makeStyles, lighten, darken} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .activity-type.warning": {
      backgroundColor: theme.palette.error.dark,
      color: purple[100],

      fontWeight: "600",
    },
    "& .warning": {
      backgroundColor: theme.palette.warning.light,
      color: "#1a3e72",
    },
  },
  filterIcon: {
    padding: "5px",
    float: "right",
  },
  activityDate: {
    color:darken(theme.palette.primary.dark, .2),
    '&.entry': {
      position:"relative",
      color:darken(theme.palette.success.dark, .2),
    },
    '&.exit': {
      position:"relative",
      color:darken(theme.palette.primary.dark, .2),
    },
    '&.exit::before': {
      content: "'Exit'",
      textAlign:"center",
      fontSize:9,
      position:"absolute",
      top:2, left:-30,
      width:25,
      border:"1px solid silver",

    },
    '&.entry::before': {
      content: "'Entry'",
      textAlign:"center",
      fontSize:9,
      position:"absolute",
      top:2, left:-30,
      width:25,
      border:"1px solid silver",

    }    
  },
  activityDateIcon: {
    marginRight:4,
    '&.entry': {
      color:theme.palette.success.light,
    },
    '&.exit': {
      color:theme.palette.primary.light,
    },
  },  
  drawerPaper: {
    width: "50%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  clickableRow: {
    cursor: "pointer",
    transition: theme.transitions.create("box-shadow", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },
  reportLinks: {
    marginBottom: "10px",
    textAlign: "center",
    flexDirection: "column",
    display: "flex",
    paddingTop: "5%",
  },
  pointer: {
    cursor: "pointer",
  },
  resultText: {
    background: theme.palette.secondary.main,
    color: "#fff"
  },
  activityAccordion: {
    overflowX: "auto"
  },
  resultStatus: {
    '&.status.enter': {
      color:theme.palette.success.dark,
    },
    '&.status.exit': {
      color:theme.palette.primary.main,
    },
    '&.status.backout-with-ticket':{
      
    },
    '&.status.errorHighlight':{
      color: theme.palette.error.dark,
    },
    '&.status.warningHighlight':{
      color: theme.palette.warning.main
    }
  },
  CredentialNumber: {
    maxWidth: "20rem",
    overflowX: "clip"
  }
}));
