import React from "react";
import {Controller} from "react-hook-form";
import {FormControl, InputLabel, MenuItem, Select, Tooltip} from "@material-ui/core";

export default function EnvironmentFormField({control, errors}) {
  return (
    <FormControl fullWidth>
      <InputLabel>Environment</InputLabel>
      <Controller
        name="environment"
        control={control}
        render={({field}) => (
          <Tooltip title="Which environment that this script will run." arrow>
            <Select
              {...field}
              defaultValue=""
              error={!!errors.environment}>
              <MenuItem value={"Both"}>Both</MenuItem>
              <MenuItem value={"Edge"}>Edge</MenuItem>
              <MenuItem value={"Bare Metal"}>Bare Metal</MenuItem>
            </Select>
          </Tooltip>
        )}
      />
    </FormControl>
  );
}
