import React, { useState, useEffect } from "react";
import apiClient from "../../../auth/apiClient";
import ListView from "../../ListView/ListView";
import {_,isEmpty} from "lodash";
import { useSelector } from "react-redux";
import FacilityService from "../../../services/FacilityService"; 
import moment from "moment";

const facilityService = new FacilityService(apiClient);

const FacilityActivity = () => {    
  const isAdmin = useSelector(state => state?.user?.isAdmin);
  const [subscriberData, setSubscriberData] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  let mappedDataForListView = [];

  useEffect(() => {
    getSubscribers(searchTerm);
  }, [searchTerm]);

  const getSubscribers = async (searchTerm) => {
      try {
        let result = await facilityService.getFacilityActivity(searchTerm);
        setSubscriberData(result.data);        
      } catch (error) {
        setSubscriberData([]);
      }
    };
  
  const formatDate = (date, tz) => {
    return isEmpty(date)
      ? ""
      : moment
          .utc(date)
          .tz(tz)
          ?.format("MM/DD/YYYY hh:mm:ss a");
  };
  
  const getMappedData = () => {
      if (subscriberData?.length > 0) {
        subscriberData.map(row => {
          mappedDataForListView.push({
            name: row.name,
            date: (row.activityDate) ? formatDate(row.activityDate, row.timeZone) : '(no activity)',
            timezone: row.timeZone,
            age: (row.activityDate) ? moment.utc(row.activityDate).fromNow() : ''
          });
        });
      }
      return mappedDataForListView;
    };
  
  const onFilterChange = term => {
    setSearchTerm(term);
    if(term.length > 0) {
      const re = new RegExp(`${term}`, 'i');
      setSubscriberData(subscriberData.filter(a=>a.name.match(re) !== null));
    }
  };
    
  if (!isAdmin) return <></>;

  return (
    <ListView
          titleBarToggle={false}
          titleBarTextToggle={true}
          titleBarAddButtonToggle={false}
          showTableHead={true}
          paginationToggle={subscriberData?.length > 0 ? true : false}
          rowsToShowPerPage={[15, 25, 50]}
          rowsToShowDefault={15}
          queryPagination={false}
          data={getMappedData()}
          sortCaseInsensitive={true}
          editButtonToggle={false}
          deleteButtonToggle={false}
          showSearch={subscriberData?.length > 0 ? true : false}
          handleSearchChange={onFilterChange}
        />
  );
};

export default FacilityActivity;