import React from "react";
import {Controller} from "react-hook-form";
import {FormControl, InputLabel, MenuItem, Select, Tooltip} from "@material-ui/core";

export default function RunTypeFormField({control, errors}) {
  return (
    <FormControl fullWidth>
      <InputLabel>Type</InputLabel>
      <Controller
        name="runType"
        control={control}
        render={({field}) => (
          <Tooltip title="Whether or not to run the script once on the machine, or every time it starts up." arrow>
            <Select {...field} error={!!errors.runType}>
              <MenuItem value={"Once"}>Once</MenuItem>
              <MenuItem value={"Continuous"}>Continuous</MenuItem>
            </Select>
          </Tooltip>
        )}
      />
    </FormControl>
  );
}