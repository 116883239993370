import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import Title from "../../../../Title";
import { useStyles } from "./styles";
import * as Yup from "yup";
import { useEnqueueSnackbar } from "../../../../../hooks/useEnqueueSnackbar";
import UserService from "../../../../../services/UserService";
import apiClient from "../../../../../auth/apiClient";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { useFormik } from "formik";
import clsx from "clsx";

const userService = new UserService(apiClient);

const UserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
});

const AddAdminUser = ({ toggleModal, createUser }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const { errors, setFieldValue, handleSubmit, values } = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: createUser,
    validationSchema: UserSchema,
  });

  return (
    <>
      <Grid container>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Title className={clsx("title")}>Add Admin User</Title>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  className={ classes.input }>
            <TextField
              className={clsx(["email", "textfield"])}
              value={values.email ?? null}
              data-value={values.email ?? ""}
              variant="outlined"
              name="email"
              fullWidth
              type="email"
              label="Email"
              onChange={(e) => setFieldValue("email", e.target.value)}
              error={errors && errors.email ? true : false}
              helperText={errors && errors.email}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classes.buttonContainer}>
            <Button
              data-id="save-button"
              startIcon={<SaveIcon />}
              buttonStyle
              variant="contained"
              color="primary"
              name="submit"
              type="submit"
              fullWidth
              className={clsx("submit-button", classes.buttonStyle)}
              onClick={handleSubmit}
            >
              Submit
            </Button>

            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classes.buttonContainer}>

            <Button
              data-id="cancel-button"
              startIcon={<CancelIcon />}
              variant="contained"
              fullWidth
              name="cancel"
              className={clsx("cancel-button", classes.buttonStyle)}
              onClick={() => toggleModal(false)}
            >
              Cancel
            </Button>
          </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};

export default AddAdminUser;
