import React from "react";
import {Controller} from "react-hook-form";
import {FormControl, TextField, Tooltip} from "@material-ui/core";

export default function TimeoutFormField({control, errors}) {
  return (
    <FormControl>
      <Controller
        name="timeoutSeconds"
        control={control}
        render={({field}) => (
          <Tooltip title="The amount (in seconds) to wait for the script to finish before considering it failed." arrow>
            <TextField
              {...field}
              fullWidth
              label="Timeout"
              error={!!errors.timeoutSeconds}
              helperText={errors.timeoutSeconds && errors.timeoutSeconds?.message}
              type="number"
              variant="outlined"
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
            />
          </Tooltip>
        )}
      />
    </FormControl>
  );
}