import React, {useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText} from "@material-ui/core";
import {useDeployableScriptsContext} from "../DeployableScriptsProvider";
import useCancellationToken from "../../../../hooks/useCancellationToken";
import apiClient from "../../../../auth/apiClient";

const RefreshManifestsDialog = () => {
  const {
    state: {refreshType},
    actions: {resetRefresh, showError, getTypeNameFromTypeId}
  } = useDeployableScriptsContext();
  const [open, setSetOpen] = useState(false);
  const [deviceType, setDeviceType] = useState(null);

  const {execute: refreshManifests, inProgress} = useCancellationToken({
    func: async function ({typeId, cancelToken}) {
      await apiClient.post(`deployable-scripts/manifest/refresh/` + typeId, {
        cancelToken: cancelToken
      });
      setSetOpen(false);
      resetRefresh();
    }, errHandleFunc: (err) => {
      showError('Failed to refresh manifests: ' + err);
    },
  });

  const performRefresh = () => {
    refreshManifests({typeId: refreshType});
  };

  const cancel = () => {
    setSetOpen(false);
    resetRefresh();
  };

  useEffect(() => {
    if (refreshType) {
      setDeviceType(getTypeNameFromTypeId(refreshType).toUpperCase());
      setSetOpen(true);
    }
  }, [refreshType]);

  return <Dialog open={open} onClose={cancel}>
    <DialogTitle>Refresh Manifests?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to instruct {deviceType ?? ""} devices to refresh their manifests?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={performRefresh} disabled={inProgress} color="secondary" autoFocus>
        Yes
      </Button>
      <Button onClick={cancel} disabled={inProgress} color="primary">
        No
      </Button>
    </DialogActions>
  </Dialog>
};

export default RefreshManifestsDialog;
