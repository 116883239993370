import React from "react";
import { useStyles } from "./_Generic.style";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import SquareChip from "../../SquareChip"
import { AUDIT_TYPES, AUDIT_SUBTYPES } from "../../../constants";

const ValidationAudit = ({ onDrawer, username, action, date, payload , ...props}) => {
  const classes = useStyles();
  const _payload = React.useMemo(() => JSON.parse(props?.history?.payload) ?? null, [props?.history?.payload]);

  const handleDetailsClick = () => {
    onDrawer("generic " + date.toString());
  }

  const getActionLabel = () => {
    switch (action) {
      case "edit":
        if (_payload?.validationOfferActive?.ActiveFlag != null) {
          return _payload?.validationOfferActive?.ActiveFlag ? "Active" : "Inactive";
        } else {
          return "Edited";
        }
      default:
        return AUDIT_SUBTYPES[props?.history?.subcategory];
    }
  };

  return (
    <>
      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}><SquareChip size="small" color="primary" text={AUDIT_TYPES[props?.history?.category]}/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action" className={clsx([classes.tdc])}>
          <div>{getActionLabel()}</div>
        </td>
        <td data-column="Details" className={clsx([classes.tdc,classes.details])}>
          {1==2 && (
          <FontAwesomeIcon onClick={handleDetailsClick} size="lg" className={classes.detailIcon} alt={JSON.stringify(_payload)} title={JSON.stringify(_payload)} icon={faInfoCircle}></FontAwesomeIcon>
          )}
        </td>
      </tr>

    </>
  );
};

export default ValidationAudit;
