import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {},



    //new
    tdc: {
        '&:before':{
            color: theme.palette.primary.main,
        }
    },    
    userInfo: {
        color: theme.palette.text.disabled,
    },
    details: {
        textAlign:"center",
    },
    detailIcon: {
        color: theme.palette.primary.dark,
        cursor:"pointer",
    },
    //end new

}));
