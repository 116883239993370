import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {

  },
  tdc: {
    '&:before':{
        color: theme.palette.primary.main,
    }
  },  
  details: {
    textAlign:"center",
  },
  detailIcon: {
    color: theme.palette.primary.main,
    cursor:"pointer",
  },
  groupName: {
    color: theme.palette.primary.light,
    '&.delete': {
        color: theme.palette.error.main,
    }
}
}));
