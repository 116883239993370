import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { useStyles } from "./_Generic.style";
import clsx from "clsx";
import { Grid } from "@material-ui/core";

import { useSelector } from "react-redux";
import { FindEntity } from "../../../state/slices/entities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { Typography } from "@material-ui/core";
import SquareChip from "../../SquareChip"
import { AUDIT_TYPES, AUDIT_SUBTYPES } from "../../../constants";

const GenericAudit = ({ onDrawer, username, action, date, payload , ...props}) => {
  const classes = useStyles();
  const _payload = JSON.parse(props?.history?.payload) ?? null;

  const handleDetailsClick = () => {
    onDrawer("generic " + date.toString());
  }

  return (
    <>
      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}><SquareChip size="small" color="primary" text={AUDIT_TYPES[props?.history?.category]}/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action" className={clsx([classes.tdc])}>
          <div>{AUDIT_SUBTYPES[props?.history?.subcategory]}</div>
        </td>
        <td data-column="Details" className={clsx([classes.tdc,classes.details])}>
          {1==2 && (
          <FontAwesomeIcon onClick={handleDetailsClick} size="lg" className={classes.detailIcon} alt={JSON.stringify(_payload)} title={JSON.stringify(_payload)} icon={faInfoCircle}></FontAwesomeIcon>
          )}
        </td>
      </tr>

    </>
  );
};

export default GenericAudit;
