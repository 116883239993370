import React, { useState, useRef, useEffect } from 'react';
import { useStyles } from './style';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Button,
  Divider,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Title from '../Title';
import clsx from 'clsx';
import useDebounce from '../../hooks/useDebounce';
import SearchBar from '../SearchBar';
import { PaginatorLocations } from '../PageableEntity';
import Pagination from '@material-ui/lab/Pagination';

export const AccordionPanel = ({
  title,
  filterComponent,
  hideAddBtn,
  mainPermission,
  details,
  searchBarPlaceHolder,
  searchBarLabel,
  onSearchChange,
  defaultSearch,
  addButtonLabel,
  addButtonClicked,
  addButtonDisabled,
  onPageChange,
  currentPage,
  paginatorLocation,
  totalCount
}) => {
  const classes = useStyles();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(defaultSearch);
  const debouncedSearchTerm = useDebounce(searchTerm, undefined);
  const refTerm = useRef(debouncedSearchTerm);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleFilters = () => {
    setFiltersOpen((prev) => !prev);
  };

  const handleSearchChange = (search) => {
    setSearchTerm(search);
  };

  useEffect(() => {
    if (
      debouncedSearchTerm !== refTerm.current &&
      (debouncedSearchTerm || debouncedSearchTerm === '')
    ) {
      refTerm.current = debouncedSearchTerm;
      onSearchChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <Grid
      container
      className={clsx('accordion-container', classes.accordionContainer)}
    >
      <Paper elevation={3} className={clsx('accordion-paper', classes.paper)}>
        <Accordion
          defaultExpanded={true}
          className={clsx('accordion-general', classes.accordionGeneral)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={clsx('expand-button')} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid item={12}>
              <Title cssSelector="title" id="accordion-panel-id">
                {title}
              </Title>
            </Grid>
          </AccordionSummary>

          {mainPermission && (
            <>
              {addButtonLabel && !hideAddBtn && (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    id="button-component"
                    className={clsx(
                      'button-component',
                      classes.buttonContainer
                    )}
                  >
                    <Button
                      className={clsx('btn-acc-add', classes.addButton)}
                      data-id="add-btn-acc-test"
                      data-testid="add-btn-acc-test"
                      variant="contained"
                      color="primary"
                      onClick={addButtonClicked}
                      disabled={addButtonDisabled}
                    >
                      {small ? 'Add' : addButtonLabel}
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                {searchBarLabel && (
                  <Grid
                    item
                    xs={10}
                    md={8}
                    id="search-container"
                    className={clsx('searchContainer', classes.searchContainer)}
                  >
                    <SearchBar
                      className={clsx('pageable-search-bar', classes.searchBar)}
                      placeholder={searchBarPlaceHolder}
                      label={searchBarLabel}
                      delay={850}
                      onChange={handleSearchChange}
                      defaultSearch={defaultSearch}
                    />
                  </Grid>
                )}
                {filterComponent && (
                  <Grid
                    item
                    xs={1}
                    className={clsx(
                      'filter-button-container',
                      classes.filterButtonContainer
                    )}
                  >
                    <IconButton
                      data-id="pageable-filter-button"
                      color="primary"
                      onClick={toggleFilters}
                      className={clsx(
                        'pageable-filter-icon',
                        classes.filterIcon
                      )}
                    >
                      <FilterListIcon
                        fontSize="large"
                        className={clsx('filter-icon', classes.icon)}
                      />
                    </IconButton>
                  </Grid>
                )}
                {paginatorLocation == PaginatorLocations.TOP && (
                  <Grid
                    item
                    xs={6}
                    md={3}
                    className={clsx(
                      'pagination-container',
                      classes.paginationContainer
                    )}
                  >
                    <Pagination
                      className={clsx('pagination-container')}
                      page={currentPage}
                      onChange={onPageChange}
                      count={totalCount}
                      color="primary"
                      shape="rounded"
                    />
                  </Grid>
                )}
              </Grid>
              {filterComponent && (
                <Grid item xs={12} className={clsx('filter', classes.filter)}>
                  <Collapse in={filtersOpen} unmountOnExit>
                    {filterComponent}
                    <Divider />
                  </Collapse>
                </Grid>
              )}
              <AccordionDetails id="accordion-details">
                {details}
              </AccordionDetails>

              {paginatorLocation == PaginatorLocations.BOTTOM && (
                <Grid
                  item
                  xs={6}
                  md={3}
                  className={clsx(
                    'pagination-container',
                    classes.paginationContainer
                  )}
                >
                  <Pagination
                    className={clsx('pagination-container')}
                    page={currentPage}
                    onChange={onPageChange}
                    count={totalCount}
                    color="primary"
                    shape="rounded"
                  />
                </Grid>
              )}
            </>
          )}
        </Accordion>
      </Paper>
    </Grid>
  );
};

AccordionPanel.defaultProps = {
  title: 'Title',
  filterComponent: null,
  mainPermission: false,
  details: null,
  buttonComponent: null
};
AccordionPanel.propTypes = {
  title: PropTypes.string,
  filterComponent: PropTypes.element,
  mainPermission: PropTypes.bool,
  details: PropTypes.element,
  buttonComponent: PropTypes.element
};

export default AccordionPanel;
