import React from "react";
import PropTypes from "prop-types";
import ItemList from "../../../List/ItemList";
import { useStyles } from "./styles";
import { Grid, Box } from "@material-ui/core";
import clsx from "clsx";
import Loading from "../../../Loading";

const AccessHolderPageableList = ({
  items,
  onItemClicked,
  isLoading
}) => {
  const classes = useStyles();

  return (
    <>
    <Box>
        <Grid container className={clsx(classes.contentRoot)}>
          {isLoading === true && (
            <Loading className={clsx(classes.loadingSpinner)} />
          )}
          {isLoading === false && (
            <Grid item xs={12} className={classes.itemList}>
              <ItemList
                className={clsx("pageable-item-list")}
                items={items}
                onRowClicked={(id) => {
                  onItemClicked(id);
                }}
                disableSelect={false}
                hideCheckBox={true}
                disableCheckBoxForID={true}
              />
            </Grid>
          )}
        </Grid>
    </Box>
    </>
  );
};

AccessHolderPageableList.defaultProps = {
  items: [],
  onItemClicked: () => {},
  isLoading: false,
};

AccessHolderPageableList.propTypes = {
  items: PropTypes.array,
  onItemClicked: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default AccessHolderPageableList;
