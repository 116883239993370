import { makeStyles, darken } from "@material-ui/core/styles";
import { AddToHomeScreenOutlined } from "@material-ui/icons";

export const useStyles = makeStyles((theme) => ({
  list: {
    width: "inherit",
  },
  chip: {
    cursor: "pointer",
    margin: "0 1%",
  },
  card: {
    width: "inherit",
    cursor: "pointer",
  },
  cardContent: {
    width: "100%",
  },
  listIcon: {
    minWidth: "50px",
  },
  chipGrid: {
    lineHeight: "50px",
  },
  icon: {
    cursor: "pointer",
  },
  filterBox: {
    marginBottom: theme.spacing(2),
  },
  filterChip: {
    cursor: "pointer",
    margin: "0 1%",
  },
  filterContainer: {
    width: "100%",
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  mobileAdd: {
    "& .MuiButton-startIcon": {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  addBtn: {
    display: "flex",
    justifyContent: "flex-end",
    maxHeight: "50px",
  },
  dateIndicator: {
    fontSize: "large",
    fontWeight: "bold",
  },
  editGrid: {
    textAlign: "right",
    margin: "0 auto",
  },
  loadingSpinner: {
    textAlign: "center",
  },
  divider: {
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2)
    }
  },
  initialScreen: {
    marginTop: "100px",
    textAlign: "center"
  },
  noResults: {
		marginTop:50,
		textAlign:"center",
		width:"98%",
		"& .no-results-text":{
			border:"1px solid",
			borderColor:darken(theme.palette.amano.warnings.fire,.3),
			padding:"8px 92px 8px 92px",
			backgroundColor: theme.palette.background.paper,
			fontSize:"14pt",
			fontStyle:"italic",
			fontWeight:"bold",
			color:darken(theme.palette.amano.warnings.fire,.2),
			opacity:0.7,
			borderRadius:3,
			boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
		},
	},
  statusDropDownGrid: {
    textAlign: "center",
  },
  statusDropDown: {
    [theme.breakpoints.up("md")]: {
      minWidth: "100px",
    },
    textAlign: "left"
  },
  modeDropDownGrid: {
    textAlign: "right",
  },
  modeDropDown: {
    [theme.breakpoints.up("md")]: {
      minWidth: "100px",
    },
    textAlign: "left"
  },
  filterContainer: {
    width: "100%",
  },
  groupToolTip: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-3)
  },
  paginationContainer: {
    justifyContent: "right",
    marginBottom: theme.spacing(1),
    display: "flex",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10)
    }
  },
  skeletonWrapper:{
		width:"96%",
		textAlign:"center",
		"&.skeleton":{
			color:theme.palette.amano.base.primary.main,
			backgroundColor:theme.palette.amano.base.primary.main,
		}
	},
  resultsDivider: {
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(2)
    }
  }
}));
