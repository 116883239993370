import React from "react";
import {Controller} from "react-hook-form";
import {FormControl, Checkbox, FormControlLabel, Tooltip} from "@material-ui/core";

export default function ContinueOnErrorFormField({control}) {
  return (
    <FormControl>
      <Controller
        name="continueOnError"
        control={control}
        render={({field}) => (
          <Tooltip title="Whether or not to continue with the next script in the list, if this script fails." arrow>
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} color="primary"/>}
              label="Continue on error"
            />
          </Tooltip>
        )}
      />
    </FormControl>
  );
}
