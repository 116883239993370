import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {FormProvider, useForm} from "react-hook-form";
import {
  Drawer,
  Box,
  Button,
  LinearProgress,
  Grid,
  Typography,
  Container
} from "@material-ui/core";
import {useDeployableScriptsContext} from "../DeployableScriptsProvider";
import RetryCountFormField from "./Fields/retryCountFormField";
import EnvironmentFormField from "./Fields/environmentFormField";
import ScriptFormField from "./Fields/scriptFormField";
import ContinueOnErrorFormField from "./Fields/continueOnErrorFormField";
import RunTypeFormField from "./Fields/runTimeFormField";
import TimeoutFormField from "./Fields/timeoutFormField";
import OrderFormField from "./Fields/orderFormField";
import useCancellationToken from "../../../../hooks/useCancellationToken";
import apiClient from "../../../../auth/apiClient";

const EditorDrawer = () => {
  const {
    state: {scripts, editObj, availableScripts},
    actions: {addNewConfiguration, updateConfiguration, cancelEdit, getTypeNameFromTypeId, showError}
  } = useDeployableScriptsContext();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleClose = () => {
    setDrawerOpen(false);
    cancelEdit();
  };

  const onFormSubmit = async values => {
    await updateConfig({values});
  };

  const {execute: updateConfig} = useCancellationToken({
    func: async function ({values, cancelToken}) {
      if (values.id) {
        const response = await apiClient.put(`deployable-scripts/configuration`, values, {
          headers: {"Content-Type": "application/json"},
          cancelToken
        });
        updateConfiguration(response.data);
      } else {
        const response = await apiClient.post(`deployable-scripts/configuration`, values, {
          headers: {"Content-Type": "application/json"},
          cancelToken
        });
        addNewConfiguration(response.data);
      }
      setDrawerOpen(false);
    }, errHandleFunc: (err) => {
      showError('Failed to Update script configuration' + err);
    },
  });

  const scriptSchema = Yup.object().shape({
    fileName: Yup.string().required().oneOf(availableScripts),
    environment: Yup.string().required().oneOf(['Both', "Bare Metal", "Edge"]),
    runType: Yup.string().oneOf(["Once", "Continuous"]),
    continueOnError: Yup.boolean().required(),
    retryCount: Yup.number().required().min(0).max(5),
    timeoutSeconds: Yup.number().required().min(0).max(600),
    order: Yup.number().required().min(0).max(999999).notOneOf(scripts?.map(x => x.order)?.filter(x => x !== editObj?.order) ?? []),
  });

  const formProvider = useForm({
    resolver: yupResolver(scriptSchema),
    defaultValues: {
      continueOnError: true,
      retryCount: 0,
      runType: "Once",
      fileName: "",
      environment: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: {isSubmitting, isValid, errors}
  } = formProvider;

  useEffect(() => {
    if (editObj) {
      setDrawerOpen(true);
      reset({...editObj});
    }
  }, [editObj]);

  return <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
    <Box my={1} mx={1} width={"30rem"} style={{height: "100%"}}>
      <Container maxWidth="sm" style={{height: "100%"}}>
        {(
          <FormProvider {...formProvider}>
            <form style={{height: "100%"}} onSubmit={handleSubmit(onFormSubmit)}>
              <Box style={{height: "95%"}} display="flex" flexDirection="column">
                <Box my={2}>
                  <Typography variant="h5">
                    {editObj?.id > 0 ? "Edit" : "Add"} Script
                    for {getTypeNameFromTypeId(editObj?.deviceTypeId ?? -1)} Devices
                  </Typography>
                </Box>
                <Box my={2} flexGrow={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <RunTypeFormField control={control} errors={errors}></RunTypeFormField>
                    </Grid>
                    <Grid item xs={12}>
                      <ScriptFormField control={control} errors={errors}></ScriptFormField>
                    </Grid>
                    <Grid item xs={6}>
                      <RetryCountFormField control={control} errors={errors}></RetryCountFormField>
                    </Grid>
                    <Grid item xs={6}>
                      <TimeoutFormField control={control} errors={errors}></TimeoutFormField>
                    </Grid>
                    <Grid item xs={6}>
                      <ContinueOnErrorFormField control={control} errors={errors}></ContinueOnErrorFormField>
                    </Grid>
                    <Grid item xs={6}>
                      <OrderFormField control={control} errors={errors}></OrderFormField>
                    </Grid>
                    <Grid item xs={12}>
                      <EnvironmentFormField control={control} errors={errors}></EnvironmentFormField>
                    </Grid>
                    {isSubmitting &&
                      <Grid item xs={12}>
                        <LinearProgress color="primary"/>
                      </Grid>
                    }
                  </Grid>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row-reverse" my={0}>
                <Button variant="contained" onClick={handleClose} disabled={isSubmitting}>Cancel</Button>
                <Box mx={1}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting || !isValid}>
                    {isSubmitting ? "Saving" : "Save"}
                  </Button>
                </Box>
              </Box>
            </form>
          </FormProvider>
        )}
      </Container>
    </Box>
  </Drawer>
};

export default EditorDrawer;
