import React, {useContext, useState} from 'react';
import {createContext} from 'react';
import {useSnackbar} from 'notistack';

export const DeployableScriptsContext = createContext({state: {}, actions: {}});
const DeployableScriptsProvider = ({children}) => {
  const [types, setTypes] = useState([]);
  const [availableScripts, setAvailableScripts] = useState([]);
  const [scripts, setScripts] = useState([]);
  const [editObj, setEditObj] = useState(null);
  const [deleteObj, setDeleteObj] = useState(null);
  const [refreshType, setRefreshType] = useState(null);
  const [rebuildType, setRebuildType] = useState(null);
  const {enqueueSnackbar} = useSnackbar();

  const value = {
    state: {
      types: types,
      availableScripts: availableScripts,
      scripts: scripts,
      editObj: editObj,
      deleteObj: deleteObj,
      refreshType: refreshType,
      rebuildType: rebuildType
    },
    actions: {
      setAvailableScripts,
      setConfiguration: (responseData) => {
        setTypes(responseData.deviceTypes);
        setAvailableScripts(responseData.availableScripts);
        setScripts(responseData.configuredScripts);
      },
      showError: (msg) => {
        enqueueSnackbar(msg, {variant: "error"});
      },
      addClick: (deviceType) => {
        setEditObj({
          continueOnError: true,
          deviceTypeId: deviceType,
          retryCount: 0,
          runType: "Once",
          fileName: "",
          environment: "",
        });
      },
      editClick: (id) => {
        setEditObj(scripts.find(x => x.id === id));
      },
      cancelEdit: () => {
        setEditObj(null)
      },
      addNewConfiguration: (newValueObj) => {
        setScripts([...scripts, newValueObj].sort((a, b) => a.order - b.order));
      },
      updateConfiguration: (newValueObj) => {
        setScripts(scripts.map(obj => {
          if (obj.id === newValueObj.id) {
            return newValueObj
          }
          return obj;
        }).sort((a, b) => a.order - b.order));
      },
      deleteClick: (id) => {
        setDeleteObj(scripts.find(x => x.id === id));
      },
      cancelDelete: () => {
        setDeleteObj(null)
      },
      removeConfigurationFromList: (id) => {
        setScripts(scripts.filter(x => x.id !== id));
      },
      getTypeNameFromTypeId: (typeId) => {
        return typeId === null || typeId === -1 ? "ALL" : types?.find(t => t.id === typeId).name;
      },
      promptRefresh: (typeId) => {
        setRefreshType(typeId)
      },
      resetRefresh: () => {
        setRefreshType(null)
      },
      performRebuild: (typeId) => {
        setRebuildType(typeId)
      },
      resetRebuild: () => {
        setRebuildType(null)
      },
    },
  };
  return (
    <DeployableScriptsContext.Provider value={value}>
      {children}
    </DeployableScriptsContext.Provider>
  )
};
export default DeployableScriptsProvider;

export function useDeployableScriptsContext() {
  return useContext(DeployableScriptsContext);
}