//core
import React, { useState, useEffect } from "react";
import { Button, Drawer, Grid, Typography,  } from "@material-ui/core";
import Select from "react-select";
import {MuiPickersUtilsProvider, KeyboardDatePicker,} from "@material-ui/pickers";
//util
import _ from "lodash";
import MomentUtils from "@date-io/moment";
import moment from "moment";
//our comps
import SearchBar from "../SearchBar";
import Title from "../Title";
import AuditCategoryRouter from "./router";
//our util
import { AUDIT_TYPES } from "../../constants";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import useHasPermissions from "../../hooks/useHasPermissions";
import apiClient from "../../auth/apiClient";
import UserService from "../../services/UserService";
//style
import clsx from "clsx";
import { useStyles } from "./index.style";
import '../ResponsiveTable/_responsive.table.css';


const userService = new UserService(apiClient);

const AuditTool = () => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const { hasPermissions } = useHasPermissions();

  //Audit Category Types
  const [displayOptions, setDisplayOptions] = useState([]);
  useEffect(() => {
    let displayAuditTypes = [];
    for (const [typeKey, typeValue] of Object.entries(AUDIT_TYPES)) {
      if (typeValue == "AMI" && !hasPermissions(["admin"])) continue;
      displayAuditTypes.push({ value: typeKey, label: typeValue });
    }
    setDisplayOptions(_.orderBy(displayAuditTypes, "label", "asc"));  //alpha sort the dropdown
  }, []);
  const [categories, setCategories] = useState([]);
  const [categoriesError, setCategoriesError] = useState("");
  const [selectStyle, setSelectStyle] = useState({
    placeholder: provided => ({ ...provided }),
    control: provided => ({ ...provided }),
  });
  useEffect(() => {
    setSelectStyle({
      placeholder: provided => {
        return categoriesError
          ? { ...provided, color: "red" }
          : { ...provided };
      },
      control: provided => {
        return categoriesError
          ? { ...provided, borderColor: "red" }
          : { ...provided };
      },
    });
  }, [categoriesError]);
  
  const handleCategoryChange = optionsChosen => {
    //optionsChosen looks like [{value: 1, label: "User"}, {value: 2, label: "Rate"}]
    var values = [];
    if (optionsChosen.length > 0) {
      _.forEach(optionsChosen, option => {
        values.push(parseInt(option.value));
      });
      setCategoriesError("");
    }
    setCategories(
      _.orderBy(values, ["label"], ["asc"])
    );
  };

  //Email Search
  const [emailSearchTerm, setEmailSearchTerm] = useState("");
  const handleEmailSearchChange = text => {
    setEmailSearchTerm(text?.trim());
  };

  //From Date
  const [fromDate, setFromDate] = useState(moment().subtract(1, "day"));
  const [fromDateError, setFromDateError] = useState("");
  const checkFromDateAgainstToDate = newDate => {
    /*
    Check to make sure:
    - From Date is before To Date
      - If not, set it to the day before To Date
    - From Date is within 30 day range of To Date
      - If not, set it to the earliest possible date in the 30 day range
    */
    let error = "";
    if (toDate?.isValid()) {
      if (!newDate.isBefore(toDate)) {
        error = "From Date must be before To Date";
      } else if (toDate.diff(newDate, "days") > 30) {
        error = "Max range of 30 days";
      }
    }
    if (error == "") {
      setToDateError("");
    }
    setFromDateError(error);
    setFromDate(newDate);
  };
  const handleFromDateAccept = chosenDate => {
    //For the modal
    if (chosenDate?.isValid()) {
      checkFromDateAgainstToDate(chosenDate);
    } else {
      setFromDateError("Please provide a date");
    }
  };
  const handleFromDateBlur = () => {
    //For typed input (modal doesn't trigger this)
    if (fromDate?.isValid()) {
      checkFromDateAgainstToDate(fromDate);
    } else {
      setFromDateError("Please select a date");
    }
  };

  //To Date
  const [toDate, setToDate] = useState(moment());
  const [toDateError, setToDateError] = useState("");
  const checkToDateAgainstFromDate = newDate => {
    /*
    Check to make sure:
    - To Date is after From Date
      - If not, set it to the day after From Date
    - To Date is within 30 day range of From Date
      - If not, set it to the latest possible date in the 30 day range
    */
    let error = "";
    if (fromDate?.isValid()) {
      if (!newDate.isAfter(fromDate)) {
        error = "To Date must be after From Date";
      } else if (newDate.diff(fromDate, "days") > 30) {
        error = "Max range of 30 days";
      }
    }
    if (error == "") {
      setFromDateError("");
    }
    setToDate(newDate);
    setToDateError(error);
  };
  const handleToDateAccept = chosenDate => {
    //For the modal
    if (chosenDate?.isValid()) {
      checkToDateAgainstFromDate(chosenDate);
    } else {
      setToDateError("Please select a date");
    }
  };
  const handleToDateBlur = () => {
    //For typed input (modal doesn't trigger this)
    if (toDate?.isValid()) {
      checkToDateAgainstFromDate(toDate);
    } else {
      setToDateError("Please select a date");
    }
  };

  //Audit History
  const [auditHistoryData, setAuditHistoryData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const fetchAuditData = async () => {
    setHasSearched(true);
    try {
      const fromDateISO = moment(fromDate)
        .startOf("day")
        .utc()
        .toISOString();
      const toDateISO = moment(toDate)
        .endOf("day")
        .utc()
        .toISOString();
      var result = await userService.GetAuditHistory(
        facilityID,
        categories,
        emailSearchTerm,
        fromDateISO,
        toDateISO
      );
      if (result.status === 200) {
        const auditHistoryResult = [];
        result.data.forEach(history => {
          auditHistoryResult.push(history);
        });
        setAuditHistoryData(
          _.orderBy(auditHistoryResult, ["dateRecorded"], ["desc"])
        );
      }
    } catch (ex) {
      if (ex.response?.status == 404) {
        setAuditHistoryData([]);
      }
    }
  };
  const handleSearchClick = () => {
    if (categories.length == 0) {
      setCategoriesError("Please select an area");
    } else if (fromDateError || toDateError) {
      return;
    } else {
      fetchAuditData();
    }
  };

  //child element wants drawer shown
  const handleDrawerCallBack = (_incoming) => {
    console.log("DRAWER WHEE",_incoming);
  }


  return (
    <>
      <Title cssSelector={classes.title}>Audit Tool</Title>
      <Grid container alignItems="center" className={clsx(classes.inputsGrid, "audit-tool")}>
        <Grid item xs={12} md={6} className={classes.inputGrid}>
          <div data-testid="categories">
            <Select
              id="categories"
              name="categories"
              placeholder={categoriesError || "Area..."}
              styles={selectStyle}
              options={displayOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={handleCategoryChange}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputGrid}>
          <SearchBar
            name="emailSearchBar"
            label="Email Search"
            placeholder="Search..."
            data-id={"email-search-bar"}
            delay={0}
            onChange={handleEmailSearchChange}
          />
        </Grid>
        <Grid item xs={6} md={3} xl={2} className={classes.fromDateGrid}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              id="fromDate"
              label="From"
              format="MM/DD/YYYY"
              disableFuture
              clearable
              value={fromDate}
              onChange={setFromDate}
              onBlur={handleFromDateBlur}
              onAccept={handleFromDateAccept}
              error={Boolean(fromDateError)}
              helperText={null}
              className={clsx("from-date")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} md={3} xl={2}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              id="toDate"
              label="To"
              disableFuture
              format="MM/DD/YYYY"
              value={toDate}
              onChange={setToDate}
              onBlur={handleToDateBlur}
              onAccept={handleToDateAccept}
              error={Boolean(toDateError)}
              helperText={null}
              className={clsx("to-date")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={11} md={3} xl={2} className={classes.errorGrid}>
          {fromDateError == toDateError
            ? fromDateError
            : fromDateError + "\n" + toDateError}
        </Grid>
        <Grid item xs={11} md={3} xl={6} className={classes.buttonGrid}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchClick}
            className={clsx("search")}
          >
            Search
          </Button>
        </Grid>
        <Grid item data-tag="spacer" xs={12} className={classes.inputGrid} />
      </Grid>
      {/* WILL NEED TO PAGINATE THIS DATA AT SOME POINT - we could use a MUI DataGrid */}
      {auditHistoryData.length != 0 ? (
        <table className={clsx("a1-responsive")}>
          <thead>
            <tr>
              <th className={clsx([classes.longHeader])}>Area</th>
              <th className={clsx([classes.longHeader])}>Date</th>
              <th className={clsx([classes.longHeader])}>User</th>
              <th className={clsx([classes.longHeader])}>Action</th>
              <th className={clsx([classes.longHeader, "detail"])}>Details</th>
            </tr>
          </thead>
          <tbody>
              {auditHistoryData.map((history, index) => {
                return (
                    <AuditCategoryRouter key={index} history={history} onDrawer={handleDrawerCallBack}/>
                );
              })}
          </tbody>
          </table>
      ) : (
        <Grid container direction="row" justify="center" className={clsx("no-history-message")}>
          <h3 hidden={!hasSearched}>No history found</h3>
        </Grid>
      )}
      <Drawer className={clsx([classes.auditDrawer, "audit", "drawer"])} anchor="right">
        <Typography>PLACEHOLDER DATA HERE - SHOULD SHOW CALLBACK DETAILS</Typography>
      </Drawer>    
    </>

  );
};

export default AuditTool;
