import React from "react";
import { RibbonContainer, RightRibbon as Ribbon } from "react-ribbons";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import clsx from "clsx";
import _ from "lodash";
import { Card, CardActions, CardContent, Typography, Button, Grid } from "@material-ui/core";
import { useConfirmationDialog } from "../../../../../hooks/useConfirmationDialog";


const AdminUserCard = ({ userInfo, tiled, handleRemove, currentUserID }) => {
  const classes = useStyles();
  const { textConfirmation } = useConfirmationDialog();
  
  const handleDeletion = async(user) => {
    let continueDelete = await textConfirmation({
      title: `Are you sure you want to delete the admin user?`,
      message: "Please enter the admin user email EXACTLY as shown in the input box below to delete.",
      textConfirmation: user.email,
    });

    if (!continueDelete) return;

    handleRemove(user.userId)
  }

  return (
    <>
      <div className={clsx([classes.root, classes.testAnimate])} key={userInfo.userId} >
        <RibbonContainer className={classes.ribbon}>
        {(userInfo.userId == currentUserID) && (<Ribbon backgroundColor="#CC5500">YOU</Ribbon >)}          
          <Card
            className={clsx([classes.card])}
            data-tiled={tiled.toString()}
          >
            <CardContent className={ clsx([classes.cardContent, "user-card"]) } data-user-id={userInfo.userId}>
                <Typography variant="p" color="textSecondary" component="p" className={ clsx(["email", tiled ? classes.tiled : ""],  classes.email) }  data-email={userInfo.email}>{userInfo.email}</Typography>
                <Typography
                  color="textPrimary"
                  component="p" 
                  className={ clsx(["name",  tiled ? classes.tiled : ""],  classes.name ) }  
                  data-tiled={tiled.toString()}
                  data-name={userInfo.firstName + " " + userInfo.lastName}
                  data-first={userInfo.firstName}
                  data-last={userInfo.lastName}
                  data-id={userInfo.userId}
                >{userInfo.firstName} {userInfo.lastName}</Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Grid item className={ clsx(["button-container"], classes.cardActions) }>
                <Button 
                  variant="outlined" 
                  color="secondary" 
                  size="small" 
                  disabled={userInfo.userId == currentUserID ? true : false} 
                  data-email={userInfo.email} 
                  className={ clsx(["user","delete", "button"],  classes.delete) } 
                  onClick={() => handleDeletion(userInfo)}
                >Delete</Button>   
              </Grid>
            </CardActions>
          </Card>
        </RibbonContainer>
      </div>
     </>  
  );
};

AdminUserCard.defaultProps = { 
  userInfo: {
    userId: "",
    firstName: "",
    lastName: "",
    email: ""
  }, 
  tiled: true };
  
AdminUserCard.propTypes = { userInfo: {
    userId: "",
    firstName: "",
    lastName: "",
    email: ""
  }, 
  tiled: PropTypes.bool };

export default AdminUserCard;

