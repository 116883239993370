import React, {useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText} from "@material-ui/core";
import {useDeployableScriptsContext} from "../DeployableScriptsProvider";
import useCancellationToken from "../../../../hooks/useCancellationToken";
import apiClient from "../../../../auth/apiClient";

const DeleteDialog = () => {
  const {
    state: {deleteObj},
    actions: {removeConfigurationFromList, cancelDelete, showError, getTypeNameFromTypeId}
  } = useDeployableScriptsContext();
  const [open, setSetOpen] = useState(false);
  const [deviceType, setDeviceType] = useState(null);

  const {execute: deleteScript, cancel: cancelQuery, inProgress} = useCancellationToken({
    func: async function ({id, cancelToken}) {
      await apiClient.delete(`deployable-scripts/configuration`, {
        params: {
          id: id
        },
        cancelToken: cancelToken
      });
      setSetOpen(false);
      removeConfigurationFromList(deleteObj.id);
    }, errHandleFunc: (err) => {
      showError('Failed to load script configuration' + err);
    },
  });

  const performDelete = () => {
    deleteScript({id: deleteObj.id});
  };
  const cancel = () => {
    setSetOpen(false);
    cancelDelete();
  };

  useEffect(() => {
    if (deleteObj) {
      setDeviceType(getTypeNameFromTypeId(deleteObj.deviceTypeId).toUpperCase());
      setSetOpen(true);
    }
  }, [deleteObj]);

  return <Dialog open={open} onClose={cancel}>
    <DialogTitle>Remove script?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to remove the script from the {deviceType ?? ""} manifest?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={performDelete} disabled={inProgress} color="secondary" autoFocus>
        Yes
      </Button>
      <Button onClick={cancel} disabled={inProgress} color="primary">
        No
      </Button>
    </DialogActions>
  </Dialog>
};

export default DeleteDialog;
