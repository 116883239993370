import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  testAnimate:{
    animation: "fadeIn",
    animationDuration: "500ms",
  },
  ribbon: {
    color: theme.palette.background.paper,
    marginLeft:5,
    marginRight:5,
    marginTop:10,
  },
  root: {},
  card: {
    '&[data-tiled="true"]': {
      //height: "200px",
    },
    '&[data-tiled="false"]': {
      //height:"100px",
    },
    
  },
  cardContent: {
    height: 95 ,
  },
  cardActions: {
    position:"absolute", 
    bottom: 16, 
    right:12,
  },
  name: {
    fontSize:"x-large",
    '&[data-tiled="true"]': {
      fontSize:"large",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize:"x-large",
    }    
  },

  email: {
    color:theme.palette.amano.main,
    fontSize:"larger",

  },
  disabled: {
    backgroundColor: theme.palette.action.selected,
  },



}));
