import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { useSelector } from "react-redux";
import { FindEntity } from "../../../state/slices/entities";
import clsx from "clsx";
import { useStyles } from "./Rate.style";
import { AUDIT_TYPES, AUDIT_SUBTYPES } from "../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { Typography } from "@material-ui/core";
import SquareChip from "../../SquareChip"

const RateAudit = ({ onDrawer, username, action, date, payload, ...props }) => {
  const classes = useStyles();
  const _payload = JSON.parse(props?.history?.payload) ?? null;
  
  const entity = useSelector(state => { //needs fixing   TODO:  MAKE IT LESS RE-RENDERY
    return FindEntity(state.entities?.EntityList ?? [], payload?.entityID);
  });

  const getActionLabel = () => {
    switch (action) {
      case "add":
        return "Created";
      case "edit":
        return _payload?.rateID ? "Assigned" : "Edited";
      case "delete":
        return _payload?.rateID ? "Deleted" : "Unassigned";
      default:
        return action;
    }
  };

  console.log("RATE", _payload);
  const getRateName = () => {
    switch (action) {
      case "add":
        return _payload?.rate?.Name;
      case "edit":
        return _payload?.rate?.Name ?? _payload?.rateID
      case "delete":
        return _payload?.rateID;
      default:
        return null;
    }
  };

  const getTargetEntityIfApplicable = () => {
    return getActionLabel()
      .toLowerCase()
      .includes("assigned")
      ? entity?.name
      : null;
  };

  const handleDrawerClick = () => { //callback to top level to render drawer about this specific entry
    onDrawer("callback " + date.toString());
  }  

  return (
    <>

      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}><SquareChip size="small" color="primary" text={AUDIT_TYPES[props?.history?.category]}/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action"  className={clsx([classes.tdc])}>
          <div >{getActionLabel()}</div>
          <div className={clsx([action,classes.rateName])}>{getRateName()}</div>
          <div className={clsx([action,classes.rateName])}>{getTargetEntityIfApplicable()}</div>
        </td>
        <td data-column="Details"  className={clsx([classes.tdc, classes.details])}>
          {1==2 && (
          <FontAwesomeIcon onClick={handleDrawerClick} size="lg" className={classes.detailIcon} alt={JSON.stringify(_payload)} title={JSON.stringify(_payload)} icon={faInfoCircle}></FontAwesomeIcon>
          )}
        </td>
      </tr>

    </>
  );
};

export default RateAudit;
