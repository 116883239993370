import React from "react";

import { useStyles } from "./User.style";
import clsx from "clsx";
import { Grid, Paper } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import SquareChip from "../../SquareChip"

const UserAudit = ({ onDrawer, username, action, date, payload , ...props}) => {
  const classes = useStyles();
  const _payload = JSON.parse(props?.history?.payload) ?? null;
  const getActionLabel = () => {
    switch (action) {
      case "add":
        return "Created";
      case "edit":
        return "Edited";
      case "delete":
        return "Deleted";
      case "login":
        return "Log In";
      case "logout":
        return "Log Out";
      case "context switch in":
        return "Log In";
      case "context switch out":
        return "Log Out";
      default:
        return action;
    }
  };

  const getUserInfo = () => {
    switch (action) {
      case "add":
      case "edit":
        //need to stop doing "\n" in HTML
        //return `Name: ${payload?.userInfo?.firstName} ${payload?.userInfo?.lastName}\n Email: ${payload?.userInfo?.email}`;
      case "logout":
      case "login":
        return null;  //we already know who logged in and out by the action on the decorator
      case "forgot password":
        return payload?.username; //This username we already know and display in the first Grid right?
      default:
        return null;
    }
  };

  const handleDetailsClick = () => {
    onDrawer("user " + date.toString());
  }

  return (
    <>
      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}><SquareChip size="small" color="primary" text="User"/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
          <div className={classes.userInfo}>{getUserInfo()}</div>
        </td>
        <td data-column="Action" className={clsx([classes.tdc])}>
          <div>{getActionLabel()}</div>
        </td>
        <td data-column="Details"  className={clsx([classes.tdc,classes.details])}>
          {1==2 && (
          <FontAwesomeIcon size="lg" onClick={handleDetailsClick} className={classes.detailIcon} alt={JSON.stringify(_payload)} title={JSON.stringify(_payload)} icon={faInfoCircle}></FontAwesomeIcon>
          )}
        </td>
      </tr>
      {/* <Grid container item zeroMinWidth xs={5} spacing={1} direction="column" wrap="nowrap" className={clsx(["", classes.orangey])}>
        <Grid xs={12} item wrap="nowrap" zeroMinWidth spacing={2}  className={classes.date}>{date}</Grid>
        <Grid xs={12} item wrap="nowrap" zeroMinWidth spacing={2} >{username}</Grid>
      </Grid>
      <Grid  item xs={2} className={clsx(["", classes.orangey])}>{getActionLabel()}</Grid>
      <Grid item xs={5} className={clsx(["", classes.orangey])}>{getUserInfo()}</Grid> */}
    </>
  );
};

export default UserAudit;
