import React from "react";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";

import { useStyles } from "./CredentialAudit.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { Typography } from "@material-ui/core";
import SquareChip from "../../SquareChip"
import { AUDIT_TYPES, AUDIT_SUBTYPES, CREDENTIAL_TYPES} from "../../../constants";


const CredentialAudit = ({ onDrawer, username, action, date, payload, ...props }) => {
  const classes = useStyles();
  const _payload = JSON.parse(props?.history?.payload) ?? null;

  console.log("CREDENTIAL", _payload);

  const getCredentialType = (_credentialType) => {
    switch (_credentialType) {
      case CREDENTIAL_TYPES.BARCODE:
        return "Barcode";
      case CREDENTIAL_TYPES.AVI:
      case CREDENTIAL_TYPES.PROX:
      case CREDENTIAL_TYPES.TOLLTAG:
        return "Prox Card";
      default:
        return null;

    }
  }

  const getActionLabel = () => {

    switch (action) {
      case "add":
        return `Created ${getCredentialType(_payload?.credential?.CredentialType ?? null)}`;
      case "edit":
        return `Edited ${getCredentialType(_payload?.credential?.CredentialType ?? null)}`;
      case "delete":
        return "Deleted";
      default:
        return action;
    }
  };

  const getCredentialReference = () => {
    switch (action) {
      case "edit":
      case "add": {
        {
          const reference =
            payload?.barcodePass?.CredentialReference ??
            payload?.credential?.CredentialReference ??
            payload?.ticket?.CredentialReference ??
            "";
          return reference;
        }
      }
      case "delete":
        return _payload?.passID ?? _payload?.credentialID ?? null;
      default:
        return null;
    }
  };

  const getAccessHolderIfApplicable = () => {
    switch (action) {
      case "edit":
      case "add": {
        return _payload?.barcodePass?.accessHolder?.name ?? _payload?.credential?.accessHolder?.name ?? null;
      }
      case "delete": {
        return _payload?.accessHolderID ?? null;
      }
      default:
        return null;
    }
  };

  const handleDetailsClick = () => {
    onDrawer("access holder " + date.toString());
  }

  return (
    <>


      <tr>
        <td data-column="Area" className={clsx(["fudge", classes.tdc])}><SquareChip size="small" color="primary" text={AUDIT_TYPES[props?.history?.category]}/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action" className={clsx([classes.tdc])}>
          <div>{getActionLabel()}</div>
          <div className={clsx([action, classes.groupName])}>{getCredentialReference()}</div>
          <div className={clsx([action])}>{getAccessHolderIfApplicable()}</div>
        </td>
        <td data-column="Details" className={clsx([classes.tdc,classes.details])}>
          {1==2 && (
          <FontAwesomeIcon onClick={handleDetailsClick} size="lg" className={classes.detailIcon} alt={JSON.stringify(_payload)} title={JSON.stringify(_payload)} icon={faInfoCircle}></FontAwesomeIcon>
          )}
        </td>
      </tr>

    </>
  );
};

export default CredentialAudit;
