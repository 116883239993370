import React from "react";
import { useStyles } from "./router.style";
import { AUDIT_TYPES, AUDIT_SUBTYPES } from "../../constants";

import UserAudit from "./Modules/User";
import RateAudit from "./Modules/Rate";
import AccessHolderAudit from "./Modules/AccessHolder";
import AccessGroupAudit from "./Modules/AccessGroup";
import CallCenterActivityAudit from "./Modules/CallCenter";
import CredentialAudit from "./Modules/CredentialAudit";
import GenericAudit from "./Modules/_Generic";
import GateAudit from "./Modules/Gate";
import ValidationAudit from "./Modules/ValidationAudit";

import useCurrentFacilityTimezone from "../../hooks/useCurrentFacilityTimezone";
import EntitiesAudit from "./Modules/EntitiesAudit";

const AuditCategoryRouter = ({ history, onDrawer }) => {

  const classes = useStyles();
  const { convertUtcDateToFormat } = useCurrentFacilityTimezone();
  const category = AUDIT_TYPES[history.category] ?? "";
  const subcategory = AUDIT_SUBTYPES[history.subcategory] ?? "";

  const props = {
    username: history?.email ?? "",
    action: subcategory.toLowerCase(),
    date: convertUtcDateToFormat(history?.dateRecorded,"MM/DD/YYYY hh:mm:ss A"),  //people will want to know more than just the _day_
    payload: JSON.parse(history?.payload),
    history: history, //why cant the children have the full info?
    //classes: classes, //unncessary relation, blocks children from flexibility.
  };

  switch (category?.toLowerCase()) {
    case "user":
      return <UserAudit onDrawer={onDrawer} {...props} />;
    case "rate":
      return <RateAudit onDrawer={onDrawer} {...props} />;
    case "access holder":
      return <AccessHolderAudit onDrawer={onDrawer} {...props} />;
    case "access group":
      return <AccessGroupAudit onDrawer={onDrawer} {...props} />;
    case "call center":
      return <CallCenterActivityAudit onDrawer={onDrawer} {...props} />;
    case "credentials":
      return <CredentialAudit onDrawer={onDrawer} {...props} />;
    case "entities":
      return <EntitiesAudit onDrawer={onDrawer} {...props} />;
    case "gate":
      return <GateAudit onDrawer={onDrawer} {...props} />;
    case "validation":
      return <ValidationAudit onDrawer={onDrawer} {...props} />;
    default:
      return <GenericAudit onDrawer={onDrawer} {...props} />;
  }
};

export default AuditCategoryRouter;
