/* eslint-disable no-mixed-spaces-and-tabs */
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  skeleton: {
    marginBottom: "2rem",
  },
  disabledRow: {
    color: theme.palette.text.disabled,
  }
}));
