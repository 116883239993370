import { makeStyles, lighten, darken, alpha, } from "@material-ui/core/styles";
import { red, grey, blue, blueGrey, orange, deepOrange} from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
  claim: {
    position:"relative",
    '&.unclaimed': {color:"silver", fontStyle:"italic"},
    '&.yours': {
      //if it's you
      color: theme.palette.primary.main,
      fontWeight:"bold",
    },
    '&.yours::after' :{
/*       content: '" "',
      width:10,
      height:10,
      marginLeft:5,
      borderRadius:"60%",
      backgroundColor: theme.palette.warning.light,
      display:"inline-block",
      top: 0,
      right:0, */
    }
  },
  
  
  chippy: {

    '&.claimed':{
      backgroundColor:theme.palette.primary.main,
    },
    '&.unclaimed':{
      backgroundColor:theme.palette.secondary.main,
    },
    '&.total':{
      color: theme.palette.text.primary,
    },
    '&.low':{
      color: theme.palette.text.primary,
      backgroundColor: grey[300],
      '& .MuiChip-avatar': {
        backgroundColor:grey[500],
      },      
    },
    '&.medium':{
      color: theme.palette.text.primary,
      backgroundColor:theme.palette.warning.main,
      '& .MuiChip-avatar': {
        backgroundColor:theme.palette.warning.dark,
      },
    },
    '&.high':{
      color: theme.palette.background.default,
      backgroundColor:red[700],
      '& .MuiChip-avatar': {
        backgroundColor:red[900],
      },
    },
    '&.critical':{
      color: theme.palette.background.default,
      backgroundColor:theme.palette.secondary.light,
      '& .MuiChip-avatar': {
        backgroundColor:red[700],
      },
    },            
  },
  chip: {
    width: theme.spacing(4),
    height: theme.spacing(4),    
    fontSize:"smaller",
    '&.claimed':{
      backgroundColor:theme.palette.primary.main,
    },
    '&.unclaimed':{
      backgroundColor:theme.palette.secondary.main,
    },
    '&.total':{
      color: theme.palette.text.primary,
    },
    '&.low':{
      color: theme.palette.text.primary,
    },
    '&.medium':{
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.warning.main,
    },
    '&.high':{
      color: theme.palette.background.default,
      backgroundColor: theme.palette.secondary.dark
    },
    '&.critical':{
      color: theme.palette.background.default,
      backgroundColor: theme.palette.secondary.light,
    },            
  },
  alert: {
    '&.low': {
      opacity:1.0,
      color:theme.palette.common,
    },
    '&.medium': {
      opacity:1.0,
      color:theme.palette.warning.main,
    },    
    '&.high': {
      opacity:1.0,
      color:theme.palette.error.dark,
    },
    '&.critical': {
      opacity:1.0,
      color:theme.palette.secondary.light,
      animation: "flash",
      animationDuration: "1s",      
    },        
  },
  chipBits: {
    display: 'flex',
    justifyContent:"flex-end",
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  centerContentColumn: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  alignContentRight: {
    textAlign: "right",
    justifyContent: "right",
    display: "flex",
    flexDirection: "column",
  },
  centerAlignment: {
    align: "center",
  },
  claimButtons: {
    minWidth: 103,
    marginRight: theme.spacing(1),
    marginBottom:2,
  },
  table: {
    tableLayout: "auto",
  },
  reportText: {
    marginRight: theme.spacing(1)
  },
  pointer: {
    cursor: "pointer",
  },
  alertFooter: {
    width: "100%",
    textAlign: "center",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      order: 3,
    },
  },
  alertscroll:{
    overflowX:"auto"
  }
}));
