import React from 'react';
import {Button, Menu, MenuItem} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import {useDeployableScriptsContext} from "../DeployableScriptsProvider";

export default function ManifestMenu({typeId}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    actions: {promptRefresh, performRebuild}
  } = useDeployableScriptsContext();
  const rebuild = () => {
    setAnchorEl(null);
    performRebuild(typeId);
  };

  const refresh = () => {
    setAnchorEl(null);
    promptRefresh(typeId);
  };

  return (
    <>
      <Button aria-haspopup="true" onClick={(e) => setAnchorEl(e.currentTarget)} disabled={typeId === -1}>
        <SettingsIcon/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={rebuild}>Rebuild Manifests</MenuItem>
        <MenuItem onClick={refresh}>Refresh Manifests</MenuItem>
      </Menu>
    </>
  );
}