import { makeStyles } from "@material-ui/core/styles";

//These styles are temporary, need to update this
export const useStyles = makeStyles(() => ({
  historyItem: {
    //textAlign: "center",
    //whiteSpace: "pre-wrap",
    //border: "1px solid black",  /* "black"??   not RGB and not Material UI Theme */
  },

}));
