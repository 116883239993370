import React, { useMemo } from "react";
import {
  Grid,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";
import Tooltip from "react-tooltip";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  timeRangeToolTip: {
    backgroundColor: `${theme.palette.grey?.main} !important`,
    maxWidth: "400px",
  },
  toolTipHeader: {},
}));


const TimeRestrictedComponent = ({ groupTimRestrictedData }) => {
  const classes = useStyles();
  const tmpRules = groupTimRestrictedData.tmpRules;
  const timeRanges = useMemo(
    () =>
      tmpRules
        ?.filter((x) => !_.isEmpty(x))
        .map(({ days, times }) => ({
          formattedTimes: `${moment.utc(times.startTime).local().format("hh:mm A")} - ${moment
            .utc(times.endTime)
            .local()
            .format("hh:mm A")}`,
          joinedDays: days.join(", "),
        })),
    [tmpRules]
  );
  return <>
    <p className={clsx("details")} data-tip data-for={groupTimRestrictedData.identifier}>
      Details
    </p>
    <Tooltip
      className={clsx("time-range-tool-tip", classes.timeRangeToolTip)}
      id={groupTimRestrictedData.identifier}
      aria-haspopup="true"
    >
      <Grid
        container
        className={clsx("grid-container")}
        direction="row"
        role="time-range-tooltip"
      >
        <Grid item className={clsx("grid-item-times")} xs={6}>
          <Box
            className={clsx("start-end-times", classes.toolTipHeader)}
            fontWeight="bold"
            fontSize="large"
          >
            Start/End Times
          </Box>
        </Grid>
        <Grid item className={clsx("grid-item-days")} xs={6}>
          <Box
            className={clsx("days-of-the-week", classes.toolTipHeader)}
            fontWeight="bold"
            fontSize="large"
          >
            Days of the Week
          </Box>
        </Grid>
        {/* {groupTimRestrictedData.tmpRules
          ?.filter(x => !_.isEmpty(x))
          .map(({ days, times }, index) => (
            <React.Fragment key={index}>
              <Grid item className={clsx("grid-item-local-time", `local-time-${index}`)} xs={6}>
                <p>{`${moment
                  .utc(times.startTime)
                  .local()
                  .format("hh:mm A")} - ${moment
                    .utc(times.endTime)
                    .local()
                    .format("hh:mm A")}`}</p>
              </Grid>
              <Grid item className={clsx("grid-item-join-days", `join-days-${index}`)} xs={6}>
                <p>{days.join(", ")}</p>
              </Grid>
            </React.Fragment>
          ))} */}
        {timeRanges.map(({ formattedTimes, joinedDays }, index) => {
          return <>
            <Grid item className={clsx("grid-item-local-time", `local-time-${index}`)} xs={6}>
              <p>{formattedTimes}</p>
            </Grid>
            <Grid item className={clsx("grid-item-join-days", `join-days-${index}`)} xs={6}>
              <p>{joinedDays}</p>
            </Grid></>
        })}
      </Grid>
    </Tooltip>
  </>
}
export default TimeRestrictedComponent;