import React, {useEffect, useState} from "react";
import apiClient from "../../../auth/apiClient";

import {
  Container, Tabs, Tab, Box
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import ScriptsTable from "./ScriptsTable";
import useCancellationToken from "../../../hooks/useCancellationToken";
import {useStyles} from "./styles";
import EditorDrawer from "./EditorDrawer";
import DeleteDialog from "./DeleteDialog";
import {useDeployableScriptsContext} from "./DeployableScriptsProvider";
import RebuildManifestsDialog from "./RebuildManifestsDialog";
import RefreshManifestsDialog from "./RefreshManifestsDialog";

function TabPanel(props) {
  const {children, index, tabValue} = props;
  return (<>
    {tabValue === index && <Box my={2}>
      {children}
    </Box>}
  </>);
}

const DeployableScripts = () => {
  const classes = useStyles();
  const {
    state: {types, availableScripts},
    actions: {setConfiguration, showError}
  } = useDeployableScriptsContext();
  const [tabValue, setValue] = useState(0);
  const {execute: getConfiguration, cancel: cancelQuery, inProgress} = useCancellationToken({
    func: async function ({cancelToken}) {
      const response = await apiClient.get(`deployable-scripts/configuration`, {
        cancelToken: cancelToken
      });
      setConfiguration(response.data);
    }, errHandleFunc: (err) => {
      showError('Failed to load script configuration' + err);
    },
  });

  useEffect(() => {
    getConfiguration();

    return () => {
      cancelQuery();
    }
  }, []);

  return <div>
    {inProgress ?
      <Container>
        <Skeleton className={classes.skeleton} variant="rect" width='50vw' height="2rem" animation="wave"/>
        <Skeleton className={classes.skeleton} variant="rect" width='50vw' height="2rem" animation="wave"/>
        <Skeleton className={classes.skeleton} variant="rect" width='50vw' height="2rem" animation="wave"/>
      </Container>
      :
      <>
        {types && <>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newValue) => setValue(newValue)}>
            <Tab key={0} label="All"/>
            {types.map((row) => (<Tab key={row.id} label={row.name}/>))}
          </Tabs>
          <TabPanel index={0} tabValue={tabValue}>
            <ScriptsTable typeFilter={-1}></ScriptsTable>
          </TabPanel>
          {types.map((row) => (
            <TabPanel key={row.id} index={row.id} tabValue={tabValue}>
              <ScriptsTable typeFilter={row.id}></ScriptsTable>
            </TabPanel>
          ))}
          <EditorDrawer/>
          <DeleteDialog/>
          <RebuildManifestsDialog/>
          <RefreshManifestsDialog/>
        </>
        }
      </>
    }
  </div>
};

export default DeployableScripts;
