import React, { useEffect, useState, useCallback } from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Drawer,
  IconButton,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@material-ui/core";
import ActivityTable from "./ActivityTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Title from "../Title";
import useHubContext from "../../hooks/useHubContext";
import useHasPermissions from "../../hooks/useHasPermissions";
import AccessHolderForm from "../Forms/AccessHolder";
import {
  ACTIVITY_CHANGE_TOPIC,
  TRANSIENT_TYPES,
  ACTIVITY_TYPES,
  BACKOUT_ACTIVITY_TYPES,
  VALID_ACCESS,
  SOFT_ANTIPASSBACK,
  USE_ACTIVITY_DATE_AS_EXIT_DATE,
  DEVICE_MODE,
} from "../../constants/index.js";
import ActivityFilters from "../Filters/ActivityFilters";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";
import apiClient from "../../auth/apiClient";
import ActivityService from "../../services/ParkingActivityService";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import clsx from "clsx";
import useCancellationToken from "../../hooks/useCancellationToken";

const activityService = new ActivityService(apiClient);

export const Activity = ({ entityId, hideTitle, accordion, tz, supportMultiEntities }) => {
  const classes = useStyles();
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const [open, setOpen] = React.useState(false);
  const [accessData, setAccessData] = useState({});
  const [activityRows, setActivityRows] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { hasPermissions } = useHasPermissions();
  const viewActivity = hasPermissions(["dashboard.activity.view"]);
  const RevTranReportPermission = hasPermissions(["RevenueTransaction"]);
  const AccHolderReportPermission = hasPermissions(["AccessholderActivity"]);
  const facilityID = useSelector(
    (state) => state.entities?.ContextID,
    shallowEqual
  );
  const activityFilters = useSelector((state) => state.activity, shallowEqual);
  const history = useHistory();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { execute: executeParkingActivityQuery } = useCancellationToken({
    func: getParkingActivity,
    errHandleFunc: () =>
      enqueueSnackbar("Failed to load activity", {
        variant: "error",
        tag: "FailedToLoadActivity",
      }),
  });
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);
  const [scopeEntityIDs, setScopeEntityIDs] = useState([]);
  const scopeList = useSelector(
    (state) => state.entityScope?.selected, shallowEqual
    ) ?? [];

  useEffect(() => {
    var idList = [];
    if(facilityGroupID && supportMultiEntities) {
      idList = scopeList?.map((entity) => { return entity.id; });
    } else {
      idList.push(entityId);
    }
    setScopeEntityIDs(idList);
  }, [facilityGroupID, scopeList, entityId]);

  useEffect(() => {
    executeParkingActivityQuery({
      credentialType: activityFilters.CredentialType,
      activityType: activityFilters.ActivityType,
      entityIds: scopeEntityIDs,
    });
  }, [scopeEntityIDs, activityFilters.CredentialType, activityFilters.ActivityType]);

  useEffect(() => {
    if (!viewActivity) return;

    let topics = scopeEntityIDs?.map((id) => {
      return id && scopeList?.some(facility => facility.id === id)
        ? `${id}`
        : `${id}_${ACTIVITY_CHANGE_TOPIC}`;
    });

    topics.forEach((topic) => {
      portalHub.subscribe(topic, async (message) => {
        let activity;
        try {
          activity = JSON.parse(message).Activity;
          if (!activity || activity == null) throw "Invalid Activity";
        } catch {
          var data = JSON.parse(message);
          activity = JSON.parse(data.Message ?? data.message).Activity;
        }
  
        if (activity) {
          addActivityRow(activity);
        }
      });
    });    

    return () => {
      topics.forEach((topic) => {
        portalHub.unsubscribe(topic);
      });
    };
  }, [PortalHubConnected, viewActivity, scopeEntityIDs]);

  async function getParkingActivity({
    entityIds,
    activityType,
    credentialType,
    cancelToken,
  }) {
    const response = await activityService.getParingActivityForFacilities(
      cancelToken,
      entityIds,
      activityType,
      credentialType
    )
    parseMessages(response.data);
  }

  const handleReportClick = (reportName) => {
    history.push(`/reports/${reportName}`);
  };

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const handleDrawerOpen = (isAccessHolderActive, accessHolderID) => {
    if (isAccessHolderActive) {
      setAccessData(
        <AccessHolderForm
          accessHolderID={accessHolderID}
          facilityID={facilityID}
          onDelete={handleDrawerClose}
        />
      );
      setOpen(true);
    } else {
      if (isAccessHolderActive != null) {
        enqueueSnackbar("Access holder deleted", {
          variant: "error",
          tag: "AccessholderDeleted",
        });
      }
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const addActivityRow = (activity) => {
    let newActivity = parseMessage(activity);
    setActivityRows((rows) => {
      if (rows.length >= 25) rows.pop();
      if (
        newActivity.result?.toLowerCase() ===
          ACTIVITY_TYPES.LostTicket.toLowerCase() &&
        newActivity.deviceMode?.toLowerCase() === DEVICE_MODE.EXIT.toLowerCase()
      )
        rows = rows.filter(
          (a) => a.credentialNumber != newActivity.credentialNumber
        );
      rows.unshift(newActivity);
      return [...rows];
    });
  };

  const getExitDate = (activityType, activityDate) => {
    return activityType === ACTIVITY_TYPES.Exit ? formatDate(activityDate) : "";
  };

  const formatDate = (date) => {
    return isEmpty(date)
      ? ""
      : moment
          .utc(date)
          .tz(tz)
          ?.format("MM/DD/YYYY hh:mm:ss a");
  };

  const parseMessages = (rows) => {
    const tempActivity = [];
    rows.forEach((value) => {
      tempActivity.push(parseMessage(value));
    });
    setActivityRows(tempActivity);
  };

  const getActivityDescription = (description, deviceMode, credentialType) => {
    if (
      deviceMode?.toLowerCase() === DEVICE_MODE.EXIT.toLowerCase() &&
      USE_ACTIVITY_DATE_AS_EXIT_DATE.indexOf(credentialType?.toLowerCase()) !==
        -1 &&
      description?.toLowerCase() === ACTIVITY_TYPES.Payment.toLowerCase()
    )
      return ACTIVITY_TYPES.Exit;
    return description;
  };

  //If activity has a secondary credential, display the credential type as "Valet Ticket"
  //Skip IssueTicket tickets that have secondardy credentials
  const determineCredentialTypeDisplayed = (message, secondary = null) => {
    const resultDescription =
    message.ResultDescription ?? message.resultDescription;
    const credentialTypeID =
    message.CredentialTypeID ?? message.credentialTypeID;
    if (secondary &&
      resultDescription != null && resultDescription == "Credential Not Found" && 
      credentialTypeID != null && credentialTypeID == 6) return "Barcode";
    if (secondary && 
      resultDescription != null && resultDescription == "Credential Not Found" &&
      credentialTypeID != null && credentialTypeID == 7) return "Prox";
    if (secondary) {
      if (credentialTypeID == 13) return "TicketMaster";
      if (credentialTypeID == 12) return "Valet Ticket";
      return "Ticket";
    }
    return message.CredentialType ?? message.credentialType;
  };

  //If activity has a secondary credential, display the secondary credential along with the credential reference
  //Skip IssueTicket tickets that have secondardy credentials
  const determineCredentialNumberDisplayed = (message, secondary = null) => {
    let originalRef =
      message.CredentialReference ?? message.credentialReference;
    const resultDescription =
      message.ResultDescription ?? message.resultDescription;
    const credentialTypeID =
      message.CredentialTypeID ?? message.credentialTypeID;
  
    if (resultDescription != null && resultDescription == "Credential Not Found") 
      return secondary == '' ? originalRef : secondary;
    if (secondary) {
      if (credentialTypeID == 13) // TicketMaster
        return secondary;
      if (credentialTypeID == 12) // Valet
      return `${
        originalRef?.length > 11 ? originalRef.slice(0, 11) : originalRef
      } (Valet ${secondary})`;
    }
    return originalRef;
  };

  const parseMessage = (message) => {
    const secondary =
      message.SecondaryCredential ?? message.secondaryCredential;
    const credentialType = determineCredentialTypeDisplayed(message, secondary);
    const isTransient =
      TRANSIENT_TYPES.indexOf(credentialType?.toLowerCase()) !== -1;
    const displayEndDate =
      USE_ACTIVITY_DATE_AS_EXIT_DATE.indexOf(credentialType?.toLowerCase()) !==
      -1;
    const name =
      (message.FirstName ?? message.firstName ?? "") +
      " " +
      (message.LastName ?? message.lastName ?? "");
    const id = message.ParkingActivityID ?? message.parkingActivityID;
    const credentialNumber = determineCredentialNumberDisplayed(
      message,
      secondary
    );
    const deviceName = message.EntityName ?? message.entityName;
    const activityDate = message.ActivityDate ?? message.activityDate;
    const resultDescription =
      message.ResultDescription ?? message.resultDescription;
    const parentEntityID = message.ParentEntityID ?? message.parentEntityID;
    const accessHolderID = message.AccessHolderID ?? message.accessHolderID;
    const isAccessHolderActive =
      message.IsAccessHolderActive ?? message.isAccessHolderActive;
    const deviceMode = message.DeviceMode ?? message.deviceMode;
    const activityType = getActivityDescription(
      message.ActivityDescription ?? message.activityDescription,
      deviceMode,
      credentialType
    );
    const hasResult =
      resultDescription != null &&
      resultDescription?.toLowerCase() != "unset" &&
      resultDescription.toLowerCase() != "valid access";
    const facilityName = message.NearestFacilityName ?? message.nearestFacilityName;
    var newActivity = {
      id: id,
      activityType: activityType,
      credentialNumber: credentialNumber,
      credentialType: credentialType,
      name: name,
      deviceName:
        activityType.toLowerCase() === "mobile payment"
          ? "Mobile Pay"
          : activityType.toLowerCase() === "roaming cashier"
          ? "Roaming Cashier"
          : deviceName,
      activityDate: formatDate(activityDate),
      result: activityType + (hasResult ? " - " + resultDescription : ""),
      parentEntityID: parentEntityID,
      accessHolderID: accessHolderID,
      isAccessHolderActive: isAccessHolderActive,
      highlight: retrieveHighlightColor(activityType, resultDescription),
      deviceMode: deviceMode,
      nearestFacilityName: facilityName
    };
    return newActivity;
  };

  const retrieveHighlightColor = (type, description) => {
    if (
      BACKOUT_ACTIVITY_TYPES.indexOf(type) != -1 ||
      (description != null &&
        description?.toLowerCase() != "unset" &&
        description != VALID_ACCESS)
    ) {
      return description?.toLowerCase() == SOFT_ANTIPASSBACK.toLowerCase()
        ? "warningHighlight"
        : "errorHighlight";
    }
    return "";
  };

  if (!viewActivity) return <></>;

  if (accordion) {
    return (
      <React.Fragment>
        <Accordion
          defaultExpanded={true}
          className={clsx("activity-panel", classes.activityAccordion)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={clsx("expand-button")} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid item xs={11}>
              {!hideTitle && <Title cssSelecto="title">Activity</Title>}
            </Grid>
          </AccordionSummary>

            <>
              <Grid container>
                <Grid item xs={12}>
                  <ActivityFilters />
                </Grid>
              </Grid>

              <AccordionDetails>
                <ActivityTable
                  filteredRows={activityRows}
                  handleDrawerOpen={handleDrawerOpen}
                  showFacility={ facilityGroupID && scopeList.length > 1}
                />
              </AccordionDetails>
              <Grid container className={clsx(classes.reportLinks)}>
                <Grid item xs={12}>
                  {RevTranReportPermission && (
                    <Link
                      color="primary"
                      data-id="RevenueTransactionLink"
                      onClick={() => handleReportClick("RevenueTransaction")}
                      className={clsx(
                        "revenue-transaction",
                        "report-link",
                        classes.pointer
                      )}
                    >
                      Revenue Transaction Report
                    </Link>
                  )}
                  {AccHolderReportPermission && (
                    <Link
                      color="primary"
                      style={{ paddingLeft: "20px" }}
                      data-id="AccessHolderActivityLink"
                      onClick={() => handleReportClick("AccessholderActivity")}
                      className={clsx(
                        "access-holder-activity",
                        "report-link",
                        classes.pointer
                      )}
                    >
                      Access Holder Activity Report
                    </Link>
                  )}
                </Grid>
              </Grid>
              <Drawer
                className={clsx(classes.drawer, "access-holder-details-drawer")}
                anchor="right"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                  root: classes.drawer,
                }}
              >
                <Button
                  onClick={handleDrawerClose}
                  className={clsx("close-access-holder-drawer")}
                >
                  Close
                </Button>
                <div style={{ padding: "20px" }}>{accessData}</div>
              </Drawer>
            </>
        </Accordion>
      </React.Fragment>
    );
  } else
    return (
      <React.Fragment>
        <Grid item xs={11}>
          {!hideTitle && <Title>Activity</Title>}
        </Grid>

          <>
            <Grid container>
              <Grid item xs={11}></Grid>
              <Grid item xs={1}>
                <IconButton
                  data-id="pageable-filter-button"
                  color="primary"
                  onClick={toggleFilters}
                  className={clsx(classes.filterIcon)}
                >
                  <FilterListIcon fontSize="large" className={classes.icon} />
                </IconButton>
              </Grid>
            </Grid>
            <Collapse in={filtersOpen} unmountOnExit>
              <ActivityFilters />
            </Collapse>
            <ActivityTable
              filteredRows={activityRows}
              handleDrawerOpen={handleDrawerOpen}
              showFacility={false}
            />
            <Drawer
              className={clsx(classes.drawer)}
              anchor="right"
              open={open}
              classes={{
                paper: classes.drawerPaper,
                root: classes.drawer,
              }}
            >
              <Button onClick={handleDrawerClose}>Close</Button>
              <div style={{ padding: "20px" }}>{accessData}</div>
            </Drawer>
          </>
      </React.Fragment>
    );
};

Activity.defaultProps = {
  entityId: undefined,
  hideTitle: false,
  accordion: false,
  supportMultiEntities: PropTypes.bool,
};
Activity.propTypes = {
  entityData: PropTypes.object,
  hideTitle: PropTypes.bool,
  accordion: PropTypes.bool,
  supportMultiEntities: PropTypes.bool,
};

export default Activity;
