import React from "react";

import { useStyles } from "./Gate.style";
import clsx from "clsx";

import SquareChip from "../../SquareChip"

const GateAudit = ({ onDrawer, username, action, date, payload , ...props}) => {
  const classes = useStyles();

  const getActionLabel = () => {
    let customAction;
    switch (action) {
      case "open gate":
        customAction = "Open Gate";
        break;
      case "close gate":
        customAction = "Close Gate";
        break;
      case "cycle gate":
        customAction = "Cycle Gate";
        break;
      default:
        customAction = action;
        break;
    }

    return customAction + ' - ' + payload?.deviceName;
  };

  return (
    <>
      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}><SquareChip size="small" color="primary" text="Gate"/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action" className={clsx([classes.tdc])}>
          <div>{getActionLabel()}</div>
        </td>
        <td data-column="Details"  className={clsx([classes.tdc,classes.details])}>
          { (payload?.vendorName !== undefined ) && <div>{'VendorName : ' + payload?.vendorName}</div>}
        </td>
      </tr>
    </>
  );
};

export default GateAudit;
