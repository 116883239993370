import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        alignContent: "right", 
        justifyContent: "right",
    },
    buttonStyle: {
        marginRight: theme.spacing(1)
    },
    input: {
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));
